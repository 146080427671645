#d-header{
    position: fixed;
    width: 272px;
    left: 0;
    top:0;
    height: 100vh;
    background: #fff;
    padding: 40px 22px;
}
#main-dashboard{
    min-height: 100vh;
    background: #F7F9FC;
}
#d-logo{
    display: flex;
    margin: 0 auto 33px;
    justify-content: center;
}
#d-logo img{
    margin: 0 auto;
    display: block;
}
#d-nav ul{
    list-style: none;
}
#d-nav ul li{ margin-bottom: 8px;}
#d-nav ul li:last-child{ margin-bottom: 0; }
#d-nav a{
    display: flex;
    align-items: center;
    padding: 11px 16px;
    column-gap: 12px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color:#000;
}
#d-nav .active a{
    font-weight: bold;
    color: #3C39B7;
}
#d-nav .active a svg path{
    fill: #3C39B7;
}
#d-nav ul.main-nav-dashboard{
    padding-bottom: 20px;
    margin-bottom: 17px;
    border-bottom: 1px solid #E3E3E3;
}

#main-dashboard{
    padding-left: 272px;
}
.d-wrapper{
    padding: 32px 50px 32px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 25px;
}
.d-meta-links ul{
    list-style: none;
    column-gap: 27px;
}
.logged-in-as,
.d-meta-links ul a{
    font-size: 14px;
    color:#000;
    text-decoration: none;
}
.logged-in-as{ margin-right: 5px; }
.d-meta-links ul .user-img a{
    display: inline-flex;
    width: 55px;
    height: 55px;
}
.d-meta-links ul svg,
.d-meta-links ul img{
    display: block;
}
.d-meta-links ul .active svg path{
    fill: #3D3ABB;
}
.d-meta-links ul li.user-img img{
    border-radius: 10px;
    max-width: 55px;
}
.d-meta-links ul a.bell{
    position: relative;
}
.d-meta-links ul a.bell.has-notifications:before{
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #ff4747;
    top: 0px;
    right: -2px;
}
.page-title-block{ width: 100%; }
.page-title-block .d-meta-links{
    position: relative;
    top:-16px;
    right: -31px;
}
.page-title-block h2 a{
    display: none;
    color:#241FFF;
    font-size:12px;
    font-weight: 700;
    text-decoration: none;
    line-height: 24px;
}
.page-title-block .add-asset{
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    align-content: flex-end;
    align-items: flex-end;
}
.page-title-block .add-asset a{ display: inline-flex; }
.d-block h3 a{ color:#000; text-decoration: none; }
.d-block h2,
.d-block h3{ font-weight: 500; }
.d-block h2{ font-size: 28px; margin-bottom: 9px; }
.d-block h3{
    margin-top: 3px;
    font-size: 21px;
    display: inline-flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 20px;
}

.d-block.score-graph{
    width: 25%;
}
.d-block.score-graph-1{
    width: 346px;
    flex-grow: 1;
}
.d-block.score-graph-1 > div > div{
    position: relative;
    width: 314px;
    max-width: 100%;
    margin: 0 auto;
}
.d-block.d-protection{
    width: 346px;
    flex-grow: 1;
}
.protection-block{
    display: flex;
    align-items: flex-end;
    margin-bottom: 25px;
    text-decoration: none;
}
.protection-block .icon-block{
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 12px;
}
.protection-block .total-val{
    font-size: 42px;
    font-weight: bold;
    color:#000;
    margin-right: 7px;
    line-height: 1;
}
.protection-block .block-label{
    color:#6D7068;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.protection-block .icon-block svg{ display: block; }
.protection-block.danger-block .icon-block svg path{ fill: #fff; }
.protection-block.danger-block .icon-block{
    background-color: #3C39B7;
}
.protection-block.compromised-block .icon-block{
    background-color: #9D9CDB;
}
.protection-block.compromised-block .icon-block svg path{ fill: #fff; }
.protection-block.corrected-block .icon-block{
    background-color: #fff;
    border:1px solid #000;
}

.protection-block.danger-block .icon-block svg{
    width: 25px;
    height: 21px;
    position: relative;
    top: -2px;
}
.d-block.d-assets{
    width: 346px;
    flex-grow: 1;
    position: relative;
}
.d-block.d-identities{
    width: 346px;
    flex-grow: 1;
}

.d-block.score-graph-5{
    width: 100%;
    flex-grow: 1;
    
}
.d-block.score-graph-5 div{
    background-color: #fff;
}
.d-block.layout-1{
    background: #fff;
    border-radius: 25px;
    padding: 20px;;
}
.d-block.layout-2 .wrap{
    background: #fff;
    border-radius: 25px;
    padding: 20px;
    overflow: hidden;
}
.d-block.d-block.layout-3 { width: 100%; }
.d-block.d-block.layout-3 .d-block-gauge .gauge-last-check{ display: none; }
.d-block.d-block.layout-3 .wrap{
    background: #fff;
    border-radius: 25px;
    padding: 20px;
    column-gap: 40px;
    row-gap: 40px;
}

.gauge-risk-level{
    height: 28px;
    background: #3D3ABB;
    color: #fff;
    min-width: 83px;
    line-height: 1;
    display: inline-flex;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    border-radius: 300px;
    margin-bottom: 26px;
}
.gauge-last-check{
    color:#A5A5A5;
    font-weight: 500;
    font-size: 12px;
}
.score-graph-1{
    position: relative;
}
.db-gauge-score{
    color: #000;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 54px;
    transform: translateX(-50%);
    background: #fff;
    z-index: 100;
    line-height: 70px;
    padding-bottom: 2px;
}
.layout-3 .db-gauge-score{ top: 104px; }
.d-gauge svg.recharts-surface > path{
    transition: all .3s ease;
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
}
.d-gauge.showbar svg.recharts-surface > path{
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
}
.d-block .add-new-item{
    width: 47px;
    height: 47px;
    display: flex;
    background: #D8D8F1;
    position: absolute;
    top:13px;
    right: 20px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
}
.d-block .add-new-item svg{
    display: block;
    position: relative;
    top:-2px;
}
.d-block.d-block.d-identities .add-new-item{
    top: auto;
    bottom: 18px ;
}

.asset-list-item{
    display: flex;
    align-items: center;
    color: #0A0E1A;
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
}
.asset-list-item .icon{
    width: 32px;
    height: 32px;
    display: flex;
    border: 1px solid #3C39B7;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 8px;
}
.asset-list-item .icon svg{ width: 14px; }
.asset-list-item .icon svg path{ fill: #3C39B7; }
.asset-list-records{ margin: 0; list-style: none; }
.asset-list-records li{ margin-bottom: 21px; }
.asset-list-records li:last-child{ margin-bottom: 0; }

.d-block.d-identities .wrap{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.d-block.d-identities .identities-total{
    color:#6D7068;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.d-block.d-identities .identities-total .identity-total-large{
    font-size: 48px;
    color: #000;
    line-height: 54px;
}
.identities-bubbles{
    display: flex;
    align-items: center; }
.identities-bubbles .identity-bubble{
    width: 85px;
    height: 85px;
    border-radius: 100%;
    border:5px solid #fff;
    background: #E0E6E6;
    overflow: hidden;
    color:#000;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.identities-bubbles .identity-bubble.has-image span{
    opacity: 0;
    display: none;
}
.identities-bubbles .identity-bubble.has-image img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover;
    display: block;
}
.identities-bubbles .identity-bubble + .identity-bubble{
    position: relative;
    margin-left: -20px;
    z-index: 3;
}
.identities-bubbles .identity-bubble:hover{
    z-index: 4;
}

.risks-total-block{
    color:#000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    column-gap: 35px;
    position: relative;
    padding-left: 35px;
    margin-bottom: 30px;
}
.risks-total-block .risks-total-large{
    font-size: 48px;
    color: #000;
    line-height: 54px;
}
.risks-total-block:before{
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    background-color: #3C39B7;
    border-radius: 100%;
    position: absolute;
    left: 10px;
    top: 13px;
}
.risks-total-block.risk-records-block:before{
    background-color: #CDD5EB;
}
.custom-select-dropdown-wrap{ position: relative; }
.custom-select-dropdown-wrap select{ display: none;}
.custom-select-dropdown-wrap .value-selected,

.d-block-dropdown select,
.risks-count-limits select{
    border: 0;
    width: 200px;
    height: 49px;
    background-color: #F7F9FC;
    border-radius: 15px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5);
    padding: 10px 18px;
    font-family: 'Aspekta';
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    background-image: url(../../assets/images/angle-down.png);
    background-repeat: no-repeat;
    background-position: center right 15px;
    cursor: pointer;
}
.risks-count-limits select::-ms-expand {
    display: none;
}
.custom-select-dropdown,
.d-block.score-graph-5 div.custom-select-dropdown{    
    box-shadow: 2px 7px 7px rgba(0, 0, 0, 0.07);
    width: 265px;
    border-radius: 11px;
    position: absolute;
    top:100%;
    right: 0;
    z-index: 3;
    display: none;
    background-color: #fff;
}
.custom-select-dropdown-wrap.active .custom-select-dropdown,
.d-block.score-graph-5 div.custom-select-dropdown-wrap.active .custom-select-dropdown{
    display: block;
}
.custom-select-dropdown .custom-select-item:first-of-type,
.d-block.score-graph-5 div.custom-select-dropdown .custom-select-item:first-of-type{
    border-radius: 11px 11px 0 0;
}
.custom-select-dropdown .custom-select-item:last-of-type,
.d-block.score-graph-5 div.custom-select-dropdown .custom-select-item:last-of-type{
    border-radius: 0 0 11px 11px ;
}
.custom-select-dropdown .custom-select-item,
.d-block.score-graph-5 div.custom-select-dropdown .custom-select-item{
    padding: 10px 16px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.custom-select-dropdown .custom-select-item:hover,
.d-block.score-graph-5 div.custom-select-dropdown .custom-select-item:hover{
    background-color: rgba(0, 0, 0, 0.03);
}
.custom-select-dropdown .custom-select-item .value-selected,
.d-block.score-graph-5 .value-selected{
    background-color: #F7F9FC;
}

.d-block-dropdown.style-1 .custom-select-dropdown-wrap .value-selected{
    background-color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 33px 10px 20px;
}
.d-block-dropdown.style-1 .custom-select-dropdown-wrap .custom-select-item{
    font-size: 14px;
    white-space: nowrap;
}
.d-block-dropdown.style-1 .custom-select-dropdown-wrap .custom-select-item .small{
    font-size: 12px;
    font-weight: 400;
}
.d-block-dropdown.style-1 .custom-select-dropdown-wrap .custom-select-dropdown{
    z-index: 3000;
    width: auto;
}

.d-block.d-alerts-block{
    width: 800px;
    flex-grow: 1;
}
.d-block.d-alerts-block h3{ margin-bottom: 0;}
.d-alerts-list{
    min-height: 400px;
}
.d-alerts-list-block{
    background-color: #F3F7F8;
    padding: 15px 17px;
    margin-top: 20px;
    border-radius: 15px;
    display: flex;
    text-decoration: none;
    color:#0A0E1A;
    font-size: 14px;
}
.d-alerts-list-block .d-alert-type { column-gap: 12px; width: 88%; }
.d-alerts-list-block .d-alert-type .d-alert-icon {
    background-color: #D8D8F1;
    width: 45px;
    height: 45px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-alerts-list-block .d-alert-type .d-alert-icon svg{
    width: 27px;
    height: 39px;
}
.d-alerts-list-block .d-alert-type .d-alert-icon svg path{ fill: #0E0F0C; }
.d-alert-type-details .label{ color:#0A0E1A; font-weight: bold; font-size: 14px; line-height: 18px;text-decoration: none; }
.d-alert-type-details .records{ color:#241FFF; font-weight: bold; font-size: 12px; line-height: 18px;text-decoration: none; }
.d-alert-type-details .info{ font-size: 12px; color:#7F807E; }
.d-alert-type-details .info .records span:first-of-type{ margin-right: 5px; }

.d-alert-level{
    height: 26px;
    color: #fff;
    min-width: 87px;
    line-height: 1;
    display: inline-flex;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    border-radius: 300px;
}
.d-alert-level.high-risk{background: #3D3ABB;}
.d-alert-level.low-risk{ background-color: #9D9CDB; }
.d-alert-time{ flex: 0 0 50%; text-align: center; }
.d-alerts-list-block .arrow-right{
    flex: 0 0 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
#d-header .d-meta-links{ display: none;}


.d-block.d-featured-article{
    width: 383px;
}
.d-block.d-featured-article .wrap{
    padding: 21px 31px;
}
.d-block.d-featured-article{
    font-size: 14px;
    line-height: 18px;
}
.d-block.d-featured-article h4{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
}
.d-block.d-featured-article .video-placeholder img{ display: block; max-width: 100%; }
.d-block.d-featured-article .video-placeholder{ margin-bottom: 20px; }
.d-block.d-featured-article a{
    text-decoration: none;
}
.d-block.d-featured-article h4 a{
    color:#0A0E1A;
    font-weight: bold;
    
}
.d-block.d-featured-article  .excerpt{
    color:#0A0E1A;
}
.d-block.d-featured-article .footer-readmore{ margin-top: 20px; }
.d-block.d-featured-article .footer-readmore .read-more{
    color:#7F807E;
}
.d-block.d-featured-article .footer-readmore .read-next{
    display: flex;
    align-items: center;
    color:#6967EF;
    column-gap: 10px;
}

.d-block-gauge{
    position: relative;
    min-width: 300px;
}

.credit-last-scan{
    width: 20%;
    flex-grow: 1;
    display: flex;
    column-gap: 14px;
}
.credit-last-scan .label{
    width: 358px;
    max-width: 100%;
    color:rgba(0, 0, 0, 0.5);
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
}
.credit-last-scan .icon{
    width: 39px; background: #3D3ABB; display: flex; border-radius: 100%; height: 39px;
    justify-content: center;
    align-items: center;
    flex : 0 0 39px;
    margin-top: 5px;
}
.credit-last-scan .icon svg{
    width: 23px;
    height: 17px;
}
.credit-last-scan .icon svg path{
    fill: #fff;
}
.credit-last-scan .label .numer-of-risks{
    font-weight: 700;
    color:#3D3ABB;
}
.credit-last-scan .label .last-scan-text{
    font-size: 12px;
    font-weight: 500;
    color: #A5A5A5;
    margin-top: 10px;
}
.credit-more-action-buttons{
    padding: 40px 0;
}
.credit-more-action-buttons .flex-wrap{ column-gap: 16px; row-gap: 16px;}
.credit-more-action-buttons a{
    border: 1px solid #3D3ABB;
    border-radius: 255px;
    height: 64px;
    padding: 20px 30px;
    font-size: 25px;
    font-weight: 400;
    display: inline-flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
}
.credit-more-action-buttons a.credit-score-scan-assets{
    background: #3D3ABB;
    color: #fff;
}
.credit-more-action-buttons a.credit-score-more-options{
    color: #3D3ABB;
    min-width: 255px;
}
.credit-more-action-buttons a.credit-score-more-options svg{
    display: block;
    position: relative;
    top:2px;
    margin-left: 10px;
}

.d-block.layout-full-width{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 14px;
    row-gap: 40px;
}
.d-main-asset-block{ margin-bottom: 30px; }
.d-main-identity-block .identity-block,
.d-main-asset-block .asset-block{
    background: #fff;
    border-radius: 15px;
    padding: 23px 30px;
    display: flex;
    flex-grow: 0.001;
    min-width: 400px;
    width: calc(33.33% - 12px);
    text-decoration: none;
    flex-direction: column;
    position: relative;
}
.user-assets-protection-layout.d-main-asset-block{ row-gap: 20px; }
.user-assets-protection-layout.d-main-asset-block .asset-block{
    width: calc(50% - 24px);
    border: 1px solid #D8D8F1;
}
.d-main-identity-block .identity-block.identity-add-item,
.d-main-asset-block .asset-block.asset-add-item{
    background: transparent;
    border:1px dashed #3D3ABB
}
.d-main-identity-block .identity-block.identity-add-item .main-title,
.d-main-asset-block .asset-block.asset-add-item .main-title{ font-weight: 400;}
.asset-block-other-details,
.asset-block-main-details{
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 12px;
    align-items: center;
}
.asset-block-main-details{
    margin-bottom: 20px;
}
.asset-block-main-details .icon{
    flex: 0  0 49px;
    width: 49px;
    height: 49px;
    border:1px solid #3D3ABB;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.asset-block-main-details .icon.email svg,
.asset-block-main-details .icon.phone svg,
.asset-block-main-details .icon.password svg{
    width: 23px;
    height: 20px;
}
.asset-block-main-details .icon.phone svg{
    width: 24px;
    height: 22px;
}
.asset-block-main-details .icon svg path{
    fill: #3D3ABB;
}
.asset-block-main-details .title{
    margin-right: auto;
    font-size: 17px;
    line-height: 24px;
    color:#101010;
}
.asset-block-main-details .title .main-title{
    font-weight: 700;
}
.asset-block-main-details .arrow{
    height: 49px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.asset-block-main-details .arrow svg{
    width: 13px;
    height: 27px;
}
.identity-block,
.asset-block{ min-height: 204px; }
.asset-block .d-alert-level{
    margin-bottom: 6px;
}
.d-main-identity-block .identity-block.identity-add-item .detail,
.d-main-asset-block .asset-block.asset-add-item .detail,
.asset-block .d-alert-scanned{
    color: #10101066;
    font-size: 14px;
    line-height: 18px;
}
.asset-block .d-alert-level.low-risk{
    background-color: #659354;
    color:#fff;
    font-weight: 700;
    padding-top: 2px;
}
.asset-block .d-alert-level.med-risk{
    background-color: #F5A02B;
    color:#fff;
    font-weight: 700;
    padding-top: 2px;
}
.asset-block .d-alert-level.high-risk{
    background-color: #C92E2A;
    color:#fff;
    font-weight: 700;
    padding-top: 2px;
}
.asset-block .asset-block-score{
    color:#3D3ABB;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3em;
    position: relative;
}
.asset-block .asset-block-score svg{ display: block; }
.asset-block-score .label{
    position:absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.circular-progress {
    --size: 84px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 12px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
    --progress : 70;
    animation: progress-animation 5s linear 0s 1 forwards;
  }
  
  .circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: square;
  }
  
  .circular-progress circle.bg {
    stroke: #F0F0F0;
  }
  
  .circular-progress circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: #3D3ABB;
  }

  .table-row-setup .value-bar .bar[data-score="1"]:after,
  .table-row-setup .value-bar .bar[data-score="2"]:after,
  .table-row-setup .value-bar .bar[data-score="3"]:after{
    background:#659354;
  }
  .table-row-setup .value-bar .bar[data-score="4"]:after,
  .table-row-setup .value-bar .bar[data-score="5"]:after,
  .table-row-setup .value-bar .bar[data-score="6"]:after,
  .table-row-setup .value-bar .bar[data-score="7"]:after{
    background:#F5A02B;
  }
  .table-row-setup .value-bar .bar[data-score="8"]:after,
  .table-row-setup .value-bar .bar[data-score="9"]:after,
  .table-row-setup .value-bar .bar[data-score="10"]:after{
    background:#C92E2A;
  }
  .asset-score-1 .circular-progress circle.fg,
  .asset-score-2 .circular-progress circle.fg,
  .asset-score-3 .circular-progress circle.fg{
    stroke: #659354;
  }
  .asset-score-4 .circular-progress circle.fg,
  .asset-score-5 .circular-progress circle.fg,
  .asset-score-6 .circular-progress circle.fg,
  .asset-score-7 .circular-progress circle.fg{
    stroke: #F5A02B;
  }
  .asset-score-8 .circular-progress circle.fg,
  .asset-score-9 .circular-progress circle.fg,
  .asset-score-10 .circular-progress circle.fg{
    stroke: #C92E2A;
  }

  @property --progress {
    syntax: "<number>";
    inherits: false;
    initial-value: 0;
  }

  .asset-block .asset-block-score.asset-score-0 .circular-progress circle.fg{
    stroke: #F0F0F0;
  }
  .identity-block .identity-block-score.asset-score-0 .circular-progress circle.fg{
    stroke: #F0F0F0;
  }
.asset-score-1 .circular-progress{ --progress: 10 }
.asset-score-2 .circular-progress{ --progress: 20 }
.asset-score-3 .circular-progress{ --progress: 30 }
.asset-score-4 .circular-progress{ --progress: 40 }
.asset-score-5 .circular-progress{ --progress: 50 }
.asset-score-6 .circular-progress{ --progress: 60 }
.asset-score-7 .circular-progress{ --progress: 70 }
.asset-score-8 .circular-progress{ --progress: 80 }
.asset-score-9 .circular-progress{ --progress: 90 }
.asset-score-10 .circular-progress{ --progress: 100 }


#add-asset-item-block.hide,
#identity-item-main-page,
#asset-item-main-page{ display: none;}

.hide-metalinks.page-title-block .d-meta-links{ display: none; }

.d-main-identity-block .identity-block .main-title{
    font-size: 17px;
    line-height: 24px;
    color:#101010;
    font-weight: 700;
}
.d-main-identity-block .identity-block .main-title span{
    color: #241FFF;
    font-size: 12px;
    line-height: 24px;
    margin-left: 15px;
}
.identity-block-items{ margin-top: 5px; margin-bottom: 5px; min-height: 64px; }
.identity-block-items .identity-circle{
    flex: 0  0 58px;
    width: 58px;
    height: 58px;
    border:5px solid #fff;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #CDD5EB;
}
.identity-block-items .phone{
    background-size: 15px;
    background-image: url(../../assets/images/phone-blue.svg);
}
.identity-block-items .email{
    background-size: 23px;
    background-image: url(../../assets/images/email-blue.svg);
}
.identity-block-items .password{
    background-size: 20px;
    background-image: url(../../assets/images/password-blue.svg);
}
.identity-block-items .person{
    background-size: 23px;
    background-image: url(../../assets/images/person-blue.svg);
}
.identity-block-main-details{
    display: flex;
    justify-content: space-between;
}
.identity-block-main-details .arrow{
    height: 27px;
    width: 13px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    background-image: url(../../assets/images/arrw-gray.svg);
}
.identity-block-other-details{
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 12px;
    align-items: center;
}
.identity-block-other-details .d-alert-scanned{
    color: #10101066;
    font-size: 14px;
    line-height: 18px;
}
.identity-block .d-alert-level.low-risk{
    background-color: #EFEFEF;
    color:#7F807E;
    font-weight: 700;
    padding-top: 2px;
    margin-bottom: 6px;
}
.identity-block .identity-block-score{
    color:#3D3ABB;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3em;
    position: relative;
}
.identity-block .label{
    position:absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.d-block .d-block-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}
.d-block.email-report-page,
.d-block.d-asset-protection-block{
    width: 800px;
    flex-grow: 1;
    max-width: 100%;
}
.d-block.d-asset-protection-block h3{ margin-bottom: 0; }

.button-style-layout{
    display: inline-flex; align-items: center;
    border:1px solid #3D3ABB;
    border-radius: 146px;
    border-radius: 146px;
    padding: 2px 12px 2px 19px;
}
.button-style-layout .icon{ margin-right: 15px }
.button-style-layout .label{ margin-right: 7px; color:#241FFF; font-size: 18px; font-weight: 500; line-height: 32px; }
.button-style-layout .icon svg{ display: block; width: 20px; height: 20px; }
.button-style-layout .icon svg path{ fill: #241FFF; }
.button-style-layout .value{
    color:#fff;
    display: inline-flex;
    width: 19px;
    height: 19px;
    border-radius: 100%;
    background-color: #241FFF;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}
.d-block.d-asset-protection-block .form-actions{ display: flex;flex-wrap: wrap; width: 100%; column-gap: 20px; row-gap: 12px; margin-bottom: 12px; }
.d-block.d-asset-protection-block .form-actions .form-custom-dropdown{
    flex: 0 0 184px;
    border:1px solid #D9D9D9;
    border-radius: 4px;
}
.form-custom-dropdown{
    position: relative;
}
.form-custom-dropdown label{
    position:absolute;
    z-index: 1;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color:rgba(0, 0, 0, 0.5);
    top:8px;
    left: 13px;
}
.form-custom-dropdown select{
    display: flex;
    width: 184px;
    height: 53px;
    border: 0;
    background-color: transparent;
    position: relative;
    z-index: 2;
    padding-left: 9px;
    font-size: 16px;
    font-weight: 700;
    color:#000;
    padding-top: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    background-image: url(../../assets/images/arrow-down.svg);
    background-size: 12px;
    background-position: center right 15px;
    background-repeat: no-repeat;
}
.form-custom-dropdown select::-ms-expand {
    display: none;
}
.form-actions .form-searchbar{ flex-grow: 1; }
.form-actions .form-searchbar input[type="search"]{
    border:1px solid #D9D9D9;
    border-radius: 4px;
    height: 53px;
    width: 100%;
    padding: 10px 10px 10px 40px;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color:#000;
    background-image: url(../../assets/images/icon-search.svg);
    background-repeat: no-repeat;
    background-position: center left 14px;
}
.form-filter-buttons{ display: flex; column-gap: 10px; margin-bottom: 18px; }
.form-filter-buttons .label-block{
    font-size: 16px;
    font-weight: 700;
    color:rgba(0, 0, 0, 0.5);
    line-height: 43px;
}
.form-filter-buttons .form-filter-button-wrap{ display: flex; flex-wrap: wrap; }
.form-filter-buttons .form-filter-button-wrap .filter-button{
    padding: 9px 62px 9px 28px;
    color:#000;
    font-size: 16px;
    background: #F7F9FC;
    border-radius: 255px;
    background-image: url(../../assets/images/icon-close.svg);
    background-size: 15px;
    background-position: center right 27px;
    background-repeat: no-repeat;
    cursor: pointer;
}
.filters-asset-risk-types{ display: flex; column-gap: 30px; margin-bottom: 36px;}
.filters-asset-risk-types .filters-asset-risk-type{
    width: 25%;
    padding: 16px;
    border-radius: 15px;
    background-color: #F7F9FC ;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border:1px solid #F7F9FC;
}
.filters-asset-risk-types .filters-asset-risk-type.active{
    border: 0;
    background: #fff;
    -webkit-box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
}
.filters-asset-risk-types .filters-asset-risk-type .intro-label{
    min-width: 80px;
    border-radius: 100px;
    background-color: #fff;
    font-size: 10px;
    font-weight: 600;
    color: #7F807E;
    line-height: 16px;
    margin-bottom: 6px;
    text-align: center;
    padding: 2px;
}
.filters-asset-risk-types .filters-asset-risk-type .intro-label.high-risk{
    background-color: #C92E2A;
    color:#fff;
}
.filters-asset-risk-types #filter-protection-show-low-risks.filters-asset-risk-type .intro-label{
    background-color: #659354;
    color:#fff;
}
.filters-asset-risk-types .filters-asset-risk-type .label{
    font-size: 12px;
    font-weight: bold;
    color:#000;
    margin-bottom: 3px;
}
.filters-asset-risk-types .filters-asset-risk-type .value{
    color:#3D3ABB;
    font-size: 48px;
    font-weight: 500;
    line-height: 1em;
}
.user-assets-protection-single{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    border:1px solid #D8D8F1;
    margin-bottom: 20px;
    padding: 20px;
}
.user-assets-protection-single.hide{ display: none; }
.usp-view-report-area{ flex: 0 0 50%; }
.usp-view-report-area.size-1{ flex: 0 0 40%; display: flex; justify-content: space-between; }
.usp-view-report-area .usp-view-report-top{ display: flex; column-gap: 18px; margin-bottom: 0; align-items: center; }
.usp-view-report-area .usp-view-report-top .icon{
    background-color: #D8D8F1; border-radius: 10px; display: inline-flex;
    width: 51px;
    height: 51px;
    justify-content: center;
    align-items: center;
}
.usp-view-report-area .usp-view-report-top .icon.asset-type-person{
    background-image: url(../../assets/images/person-black.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 23px;
}
.usp-view-report-area .usp-view-report-top .icon.asset-type-phone{
    background-image: url(../../assets/images/phone-black.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
}
.usp-view-report-area .usp-view-report-top .icon.asset-type-password{
    background-image: url(../../assets/images/password-black.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26px;
}
.usp-view-report-area .usp-view-report-top .icon.asset-type-email{
    background-image: url(../../assets/images/email-black.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
}
.usp-view-report-area .usp-view-report-top .icon.asset-type-livefeed{
    background-image: url(../../assets/images/alerts-black.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
}
.usp-view-report-area .usp-view-report-top .title{
    font-size: 16px;
    color:#0A0E1A;
    font-weight: bold;
}
.usp-view-report-area .usp-view-report-top .detail{
    font-size: 14px;
    color:#8D9BA9
}
.usp-view-report-area .usp-view-report-top .icon svg{ width: 30px; height: 44px;}
.usp-view-report-area .usp-view-report-top .icon svg path{ fill:#0E0F0C; }
.usp-view-report-bottom{
    display: flex; flex-wrap: wrap;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    column-gap:22px;
    row-gap: 22px;
    align-items: center;
}
.usp-view-report-bottom .view-report{
    color:#241FFF; text-decoration:none; display: inline-flex; font-weight: bold;
}
.whole-block-link{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
}
.asset-protection-detail .view-report{
    margin-top: 5px;
    color:#241FFF; text-decoration:none; display: inline-flex; font-weight: bold;
    font-size: 13px;
    position: relative;
    z-index: 4;
}
.usp-view-risk-area{
    flex: 0 0 25%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.usp-view-risk-area.size-1{ flex: 0 0 20%; }
.asset-type-livefeed .usp-view-risk-area.size-1 .value{ display: none; }
.usp-view-risk-area .value{
    color:#000000;
    font-size: 34px;
    font-weight: 600;
    line-height: 52px;
}
.usp-view-risk-area .risk-type{
    min-width: 80px;
    border-radius: 100px;
    background-color: #F7F9FC;
    font-size: 12px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.4);
    line-height: 16px;
    text-align: center;
    padding: 5px;
}
.usp-view-risk-area .risk-type.high{
    background-color: #3D3ABB;
    color: #fff;
}
.usp-view-clean-area.size-1{ flex: 1; }
.usp-view-clean-area{
    flex: 0 0 25%;
    display: inline-flex;
    justify-content: flex-end;
    column-gap: 26px;
    flex-wrap: wrap;
}
.risk-action a,
.usp-view-clean-area .options{
    width: 37px;
    height: 37px;
    display: inline-flex;
    background: #F7F9FC;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}
.usp-view-clean-area .clean-this{
    border: 1px solid #3D3ABB;
    color: #3D3ABB;
    font-size: 15px;
    font-weight: bold;
    padding: 0;
    display: inline-flex;
    align-items: center;
    column-gap: 5px;
    border-radius: 100px;
    text-decoration: none;
    min-width: 0;
    width: 34px;
    height: 34px;
    justify-content: center;
}
.usp-view-clean-area .clean-this-arrow{
    flex: 0 0 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.usp-view-clean-area.full-btn .clean-this{
    width: auto;
    min-width: 93px;
    padding-left: 10px;
    padding-right: 10px;
}
#user-assets-protection-layout{ min-height: 225px; }
.usp-view-clean-area .clean-this svg{ width:9px; }
.usp-view-clean-area .clean-this svg path{fill:#3D3ABB;}


.protection-options,
.d-block.d-multiple-blocks-small{
    width: 383px;
}
.d-block.d-multiple-blocks-small .wrap{
    padding: 30px 25px;
    margin-bottom: 26px;
}
.d-block-plan-block .title{
    font-weight: 700;
    font-size: 21px;
    color: #0A0E1A;
    margin-bottom: 5px;
}
.d-block-plan-block .value{ font-size: 16px; color:#000; font-weight: 400; }
.d-block-plan-block .value a{ color:#241FFF; text-decoration: none; margin-left: 10px;}
.d-block-plan-block .value a span{ color:#000; }
.d-block-plan-block + .d-block-plan-block{ margin-top: 40px; }

.table-row-setup{ font-size: 16px; color:#000; display: flex; flex-wrap: wrap; margin-top: 10px; }
.table-row-setup .label{ width: 50%; flex-grow: 1; }
.table-row-setup .value-bar{
    width: 50%; flex-grow: 1;
    display: inline-flex;
    justify-content: flex-end;
    column-gap: 10px;
    row-gap: 10px;
    align-items: center;
}
.table-row-setup .value-bar .bar{
    width:86px;
    height: 16px;
    border:1px solid #D8D8F1;
    border-radius: 255px;
    display: inline-flex;
    position: relative;
}
.table-row-setup .value-bar .bar:after{
    top: -1px;
    left: 0;
    width: 0;
    bottom: -1px;
    position: absolute;
    background: #241FFF;
    content: "";
    display: block;
}
.table-row-setup .value-bar .bar[data-score="1"]:after{
    width: 10%;
    border-radius: 255px 0  0 255px;
}
.table-row-setup .value-bar .bar[data-score="2"]:after{
    width: 20%;
    border-radius: 255px 0  0 255px;
}
.table-row-setup .value-bar .bar[data-score="3"]:after{
    width:30%;
    border-radius: 255px 0  0 255px;
}
.table-row-setup .value-bar .bar[data-score="4"]:after{
    width:40%;
    border-radius: 255px 0  0 255px;
}
.table-row-setup .value-bar .bar[data-score="5"]:after{
    width:50%;
    border-radius: 255px 0  0 255px;
}
.table-row-setup .value-bar .bar[data-score="6"]:after{
    width:60%;
    border-radius: 255px 0  0 255px;
}
.table-row-setup .value-bar .bar[data-score="7"]:after{
    width:70%;
    border-radius: 255px 0  0 255px;
}
.table-row-setup .value-bar .bar[data-score="8"]:after{
    width:80%;
    border-radius: 255px 0  0 255px;
}
.table-row-setup .value-bar .bar[data-score="9"]:after{
    width:90%;
    border-radius: 255px 0  0 255px;
}
.table-row-setup .value-bar .bar[data-score="10"]:after{
    width:100%;
    border-radius: 255px;
}
.d-block .weekly-activity h3{ margin-bottom: 0;}

.back-to-previous-page{ margin-top: -35px; width: 100%; }
.back-to-previous-page a{
    color:#241FFF;
    text-decoration: none;
    font-size: 21px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    column-gap: 20px;
}
.active-notifications .back-to-previous-page{
    margin-top: -34px;
    z-index: 300000;
    position: relative;
}
.d-block.email-report-page .wrap{ padding: 48px; }

.d-block.email-report-page .d-block-header,
.d-block.email-report-page h2{ margin-bottom: 40px; }
.d-block.email-report-page .d-block-header .value{
    color:#000000;
    font-size: 32px;
    font-weight: 600;
    text-decoration: underline;
}
.d-block.email-report-page .d-block-header .report-number-and-scan{
    font-size: 16px;
    color:rgba(0, 0, 0, 0.4)
}

.large-score .value{
    color: #000000;
    font-size: 58px;
    font-weight: 600;
    line-height: 64px;
}
.large-score .risk-type{
    min-width: 80px;
    border-radius: 100px;
    background-color: #F7F9FC;
    font-size: 20px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.4);
    line-height: 16px;
    text-align: center;
    padding: 14px 20px;
}
.large-score .risk-type.high{
    background-color: #3D3ABB;
    color: #fff;
}
.d-block.email-report-page .large-score-area{ margin-bottom: 20px; }
.risk-record-block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #808080;
}
.risk-record-block:last-of-type{ border-bottom: 0; }
.risk-record-block .risk-record-name{
    width: 50%;
    flex-grow: 1;column-gap: 20px;
    display: inline-flex;
    align-items: center;
}
.risk-record-block .risk-record-name .title{
    font-weight: 700;
    color:#000;
    font-size: 16px;
}
.risk-record-block .risk-record-name .url{ color: #8D9BA9; font-size: 14px; }
.risk-record-block .risk-score-and-type{ width: 50%; flex-grow:  1; display: inline-flex; column-gap: 20px; flex-wrap: wrap; }
.risk-record-block .risk-score-and-type .risk-score{ font-weight:500; font-size: 34px; color:#000; }
.risk-record-block .risk-score-and-type .risk-score span{
    color: #8D9BA9;
    font-size: 14px;
}
.risk-record-block .risk-score-and-type .risk-type{ color:#241FFF; font-size: 24px; font-weight: 600; }
.risk-record-block .risk-action{ flex: 0 0 37px; display: none; }

.risk-single-actions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 35px;
    margin-bottom: 20px;
    display: none;
}
.risk-single-actions.no-action{ opacity: 0; visibility: hidden; }
.risk-single-actions .clean-this{
    border: 1px solid #3D3ABB;
    color: #3D3ABB;
    font-size: 24px;
    font-weight: bold;
    padding: 18px 18px;
    display: inline-flex;
    align-items: center;
    column-gap: 5px;
    border-radius: 100px;
    text-decoration: none;
    background-color: #fff;
}
.risk-single-actions .clean-this svg{ width:20px; transform: rotate(-180deg);}
.risk-single-actions .clean-this svg path{fill:#3D3ABB;}
.risk-single-actions .options{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.risk-single-actions .options svg{
    height: 31px;
    width: 6px;
}
.table-setup-email-reports{
    padding: 0 20px 20px;
    font-size: 21px;
    font-weight: 500;
    line-height: 28px;
    color:#000
}
.table-setup-email-reports .table-setup-email-report{
    padding: 20px 0;
    border-bottom: 1px solid #D8D8F1;
}
.table-setup-email-reports .table-setup-email-report:last-of-type{ border-bottom: 0; }
.table-setup-email-reports .table-setup-email-report.action-require-block .th span{ display: block;}
.table-setup-email-reports .table-setup-email-report.action-require-block .td{
    font-weight: bold;
    color:#3D3ABB;
}
.risk-activity-text-block{ margin-bottom: 30px; }
.risk-activity-text-block .title{ font-size: 21px; font-weight: 400; color: #000; margin-bottom: 5px; }
.risk-activity-text-block .content{
    display: flex; column-gap: 10px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
}
.risk-activity-text-block .content .icon{
    background: #3D3ABB;
    display: flex;
    border-radius: 100%;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex: 0 0 24px;
    margin-top: 5px;
}
.risk-activity-text-block .content .icon svg{ width: 14px; }
.risk-activity-text-block .content .icon svg path{ fill: #fff; }
.risk-activity-text-block .content .scanned-result{
    color:#241FFF;
    font-weight: bold;
}
.d-block.layout-2.protection-options .wrap{ padding-bottom: 30px; }

/**/
.d-block.full-width{ width: 100%; }
.classic-d-block .wrap,
.d-block.d-gauge.layout-3 .wrap,
.d-block.layout-2.d-asset-protection-block .wrap{
    padding: 30px 25px;
}
.classic-d-block .wrap p,
.classic-d-block h4{ margin-bottom: 20px; }
.classic-d-block .wrap p:last-of-type{ margin-bottom: 0; }
.classic-d-block .wrap{
    background: #fff;
    border-radius: 25px;
    font-size: 14px;
    color: #707070;
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.classic-link a{
    color:#241FFF;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
}
.classic-d-block .wrap.has-sticky-link{ position: relative; padding-bottom: 70px; }
.has-sticky-link .sticky-link{
    position: absolute;
    left: 25px;
    bottom: 20px;
}

.alert-bubble-block{
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 40px;
}
.alert-bubble-block .d-alert-block{
    width: 480px;
    max-width: 100%;
}
.alert-bubble-block .d-alert-block h4{ padding-right: 30px; }
.alert-bubble-block .d-alert-block .alert-closer{
    position: absolute;
    top: 18px;
    right: 18px;
}
.alert-bubble-block .d-alert-block .alert-closer svg{ width: 14px; display: block; }
.alert-bubble-block .d-alert-block .alert-closer svg path{ fill: #000; }
.last-scanned{
    color: #10101066;
    font-size: 14px;
    line-height: 18px;
}
.last-scan-text{ opacity: 0; }
.loaded.last-scan-text{ opacity: 1;}

.d-block.d-semi-large{
    width: 800px;
    flex-grow: 1;
}
.help-page.custom-template-1 #main-dashboard{
    background-color: #F7F9FC;
}
.how-can-help-block{
    margin-bottom: 30px;
}
.how-can-help-form{
    width: 100%;
    max-width: 527px;
    margin:  0 auto;
}
.how-can-help-form h2{
    margin-bottom: 25px;
    text-align: center;
}
.how-can-help-form .form-field-block{
    position: relative;
}
.how-can-help-form .form-field-block input[type="search"]{
    border:1px solid #dfdfdf;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 700;
    color:#000;
    background-image: url(../../assets/images/icon-search.svg);
    background-repeat: no-repeat;
    background-position: center left 20px;
    font-weight: 400;
    padding: 17px 18px 17px 45px;
    font-size: 16px;
    width: 100%;
}
.help-grid-boxes.flex-wrap-block.col-gap-30 .one-half{
    width: calc(50% - 15px);
    padding-bottom: 100px;
    margin-bottom: 0;
}
.help-grid-boxes.grid-icon-boxes .grid-icon-box{
    position: relative; margin-bottom: 0;
    border: 0;
    background: #fff;
    border-radius: 11px;
    text-align: center;
}
.help-grid-boxes.grid-icon-boxes .grid-icon-box .icon{
    height: 106px; width: 106px; margin: 0 auto 24px;
    border-radius: 100%;
    display: flex;
    background: rgba(250, 250, 255, 1);
    justify-content: center;
    align-items: center;
}
.help-grid-boxes.grid-icon-boxes .grid-icon-box.grid-icon-1 .icon img{ width: 62px; }
.help-grid-boxes.grid-icon-boxes .grid-icon-box.grid-icon-2 .icon img{ width: 70px; }
.help-grid-boxes.grid-icon-boxes .grid-icon-box.grid-icon-3 .icon svg{ width: 53px; margin-left: -15px; }
.help-grid-boxes.grid-icon-boxes .grid-icon-box .learn-more{
    position: absolute;
    left:40px;
    bottom: 40px;
}
.btn.solid-white-bg{
    background: #fff;
    color: #3D3ABB;
    padding: 13px 25px;
    font-size: 16px;
}
.linklist-with-bg-and-arrow .icon-block{
    background-color: #D8D8F1;
    border-radius: 10px;
    display: inline-flex;
    width: 51px;
    height: 51px;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 20px;
    top:15px
}
.linklist-with-bg-and-arrow.has-icon-block a{
    padding: 15px 57px 15px 91px;
}
.linklist-with-bg-and-arrow .icon-block svg{ width: 29px; height: 29px; }
.linklist-with-bg-and-arrow .icon-block svg path {
    fill: #0E0F0C;
}
.linklist-with-bg-and-arrow{ list-style: none; margin: 0; padding: 0;}
.linklist-with-bg-and-arrow li{ margin-bottom: 18px;}
.linklist-with-bg-and-arrow li:last-of-type{ margin-bottom: 0; }
.linklist-with-bg-and-arrow a{
    display: flex;
    background-color: #F3F7F8;
    border-radius: 15px;
    padding: 15px 57px 15px 20px;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color:#0A0E1A;
    font-size: 16px;
    font-weight: bold;
    position: relative;
}
.linklist-with-bg-and-arrow a .label{ text-transform: capitalize; }
.linklist-with-bg-and-arrow a .date{ font-size:14px; color: #7F807E; font-weight: 400; }
.linklist-with-bg-and-arrow .icon{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.linklist-with-bg-and-arrow svg{ width: 24px; }
.linklist-with-bg-and-arrow svg path{
    fill:#979A9A;
}

/* Dashboard Form */
.dashboard-forms{
    width: 100%; max-width: 750px;
    margin: 0 auto;
    min-height: 60vh;
}
.dashboard-forms.full-width{ max-width: 100%; }
.dashboard-forms .label{ margin-bottom: 5px; }
.dashboard-forms .label label{
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.dashboard-forms .label label.text-bold{ font-weight: bold; }
.dashboard-forms .label label span.desc{ color:#7E8277; }
.dashboard-forms .label label.text-bold span.desc{ font-weight: 400; }
.dashboard-forms .input-field{
    border:1px solid #dfdfdf;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 700;
    color:#000;
    font-weight: 400;
    padding: 17px 18px;
    font-size: 16px;
    width: 100%;
}
.dashboard-forms .dashboard-form-field{ margin-bottom: 22px; }
.dashboard-forms .submit-field .field{ column-gap: 30px; row-gap: 30px; flex-wrap: wrap; }

.dashboard-forms .submit-field .btn-type,
.dashboard-forms .submit-field button,
.dashboard-forms .submit-field input{
    min-width: 162px;
    background: #3D3ABB;
    border: 1px solid #3D3ABB;
    color:#fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    display: inline-flex;
    border: 0;
    justify-content: center;
    border-radius: 15px;
    padding: 14px;
    min-width: 162px;
    cursor: pointer;
}
.dashboard-forms .submit-field .btn-type{
    background: transparent;
    color: #3D3ABB;
    border: 1px solid #3D3ABB;
    text-decoration: none;
    line-height: 16px;
}
.dashboard-forms .submit-field button.delete{
    color:#9D9CDB;
    border: 1px solid #9D9CDB;
    background-color: transparent;
}
.dashboard-forms .dashboard-form-fields.account-main-form .dashboard-form-field.submit-field{ margin-bottom: 0; }
.dashboard-forms .dashboard-form-field-group{
    display: flex; column-gap: 40px;
    position: relative;
    padding: 40px 0 20px;
    border-bottom: 1px solid #E4E7EC;
}
.dashboard-forms .dashboard-form-field-group.no-border{border-bottom: 0; }
.dashboard-forms .dashboard-form-field-group:last-of-type{ border-bottom: 0; padding-bottom: 0; }
.dashboard-forms .dashboard-form-field-group:first-of-type{ padding-top: 0; }
.dashboard-forms .dashboard-form-field-group.no-padding-top{padding-top: 0; }
.dashboard-form-field-group .dashboard-form-field.label-field{
    flex:  0 0 300px;
}
.dashboard-form-field-group .image-holder{ position: relative; display: inline-flex;}
.dashboard-form-field-group .image-holder img{
    max-width: 100%;
    border-radius: 30px;
    display: inline-flex;
}
.dashboard-form-field-group .image-holder .icon svg{ display: inline-flex; }
.dashboard-form-field-group .image-holder .icon{
    position: absolute;
    bottom: -8px;
    right: -8px;
    display: inline-flex;
}
.dashboard-forms .dashboard-form-field-group .desc{ margin-bottom: 20px; }
.desc.short{max-width: 200px;}
.button-with-icon .icon{ display: inline-flex; }
.button-with-icon{
    display: inline-flex;
    column-gap: 8px;
    align-items: center;
    padding: 8px 12px;
    text-decoration: none;
    border:1px solid #3D3ABB;
    border-radius: 6px;
}
.button-with-icon .text{
    color:#3D3ABB;
    font-weight: bold;
    font-size: 16px;
}
.dashboard-form-fields.account-main-form{ flex-grow: 1;}
.dashboard-forms .dashboard-form-fields.account-main-form .dashboard-form-field{ margin: 0;}
.dashboard-forms .dashboard-form-fields.col-gap-20{
    column-gap: 20px;
    row-gap: 20px;
}
.dashboard-forms .dashboard-form-fields.col-gap-20 .one-half{ width: calc(50% - 10px); }
.dashboard-forms .info-response{
    display: flex;
}
.dashboard-forms .info-response .response-block{
    display: inline-flex;
    align-items: center;
    column-gap: 5px;
    margin-top: 10px;
}
.response-block.successful{
    color:#0F973D;
    background: #E7F6EC;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 12px;
}
.response-block.successful .icon{
    width: 12px;
    height: 12px;
    border-radius: 100px;
    background-color: #0F973D;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.response-block.successful .icon svg{
    display: inline-flex;
    width: 8px;
}
.response-block.successful .icon svg path{ fill: #fff; }
#main-dashboard.active-notifications .page-title-block:first-of-type{
    position: fixed;
    top: 0;
    left: 272px;
    padding: 32px 50px 0;
    z-index: 100000;
    background: #F7F9FC;
    width: auto;
    right: 0;
}
#modal-custom-size{
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    width: calc(100% - 272px);
    background: rgba(116, 116, 116, 0.5);
    left: 272px;
    position: fixed;
    z-index: 100;
    top: 86px;
    backdrop-filter: blur(5px);
}
#modal-custom-size.show{
    width: calc(100% - 272px);
    height: calc(100vh - 86px);
}
#modal-notification{
    width: 0;
    height: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    z-index: 101;
    top: 86px;
    right: 0;
    display: none;
    position: fixed;
    padding: 21px;
}
#modal-notification.show{
    opacity: 1;
    width: 536px;
    background-color: #fff;
    height: calc(100vh - 86px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-width: 100%;
}
/* width */
#modal-notification.show::-webkit-scrollbar {
    width: 5px;
}

  /* Track */
#modal-notification.show::-webkit-scrollbar-track {
    background: #f1f1f1;
}

  /* Handle */
#modal-notification.show::-webkit-scrollbar-thumb {
    background: #D8D8F1;
}

  /* Handle on hover */
#modal-notification.show::-webkit-scrollbar-thumb:hover {
    background: #3C39B7;
}


#modal-notification h4{ margin-bottom: 20px; }
.notification-date{
    font-size: 15px;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
}

.see-all-notifications{
    margin-top: 20px; text-align: center;
    border-top: 1px solid #E9E9E9;
    padding-top: 23px;
}
.see-all-notifications a{
    font-size: 15px;
    font-weight: 500;
    color: #000;
    text-decoration: none;
}
.show-title-bar-loader .loader-spinner,
.d-identities .loader-spinner{ top: 50px; }
.show-title-bar-loader .wrapper-setup .loader-spinner{ top:0; }
.loader-spinner{
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1000;
    border-radius: 0 0 25px 25px;
    transition: all ease 300ms;
}
.modal-bg .loader-spinner { border-radius: 0; }
.loaded .loader-spinner{ display: none; }
.loader-spinner.loaded{
    display: none;
}
.loader-spinner .loader{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 100;
    border-radius: 0 0 25px 25px;
}
.loader-spinner .loader:before {
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -10px;
    animation: rotation .6s infinite linear;
    border-left: 6px solid rgb(216 216 241);
    border-right: 6px solid rgb(216 216 241);
    border-bottom: 6px solid rgb(216 216 241);
    border-top: 6px solid rgb(60 57 183);
    border-radius: 100%;
    text-indent: -9000px;
    box-sizing: border-box;
}
.loader-spinner .loader.big:before { height: 40px; width: 40px; margin-top: -20px; margin-left: -20px; }
@-webkit-keyframes rotation { from { -webkit-transform: rotate(0deg); } to { -webkit-transform: rotate(359deg); } }
@-moz-keyframes rotation { from { -moz-transform: rotate(0deg); } to { -moz-transform: rotate(359deg); } }
@-o-keyframes rotation { from { -o-transform: rotate(0deg); } to { -o-transform: rotate(359deg); } }
@keyframes rotation { from { transform: rotate(0deg); } to { transform: rotate(359deg); } }
#protection-assets-holder{
    padding: 20px; text-align: center;
}
#protection-assets-holder.hide{ display: none; }
.hide-element{ display: none !important; }
.hide-visibility{
    opacity: 0 !important;
    visibility: hidden !important;
}

#protection-filter{ cursor: pointer; }
#protection-filter.empty .value{ display: none; }

.full-page-loader{
    position: absolute;
    top: 99px;
    left: 50px;
    right: 50px;
    bottom: 0px;
    border-radius: 25px;
    overflow: hidden;
}
.email-report-page .full-page-loader{ top: 0; left: 0; right: 0; }
#protection-asset-wrap{ padding-bottom: 40px;}
#protection-asset-wrap .loader-spinner{ top: -10px; left: -10px; right: -10px; }
#protection-asset-wrap.loaded{ padding-bottom: 0; }

.error-text-holder{
    color: #f00;
    margin-bottom: 20px;
    border-bottom: 1px solid;
    padding: 0 0 10px;
    font-size: 16px;
}
.error-text-holder.success{
    color:#0F973D;
    border-color: #0F973D;
}
#file{ display: none; }
.recharts-wrapper svg,
.recharts-wrapper g,
.recharts-wrapper path{ outline: 0;}

.custom-size-setup-1{
    width: calc(100% - 423px);
}
.custom-size-setup-1 .d-block{ margin-bottom: 24px; width: 100%;}


@media only screen and (max-width:900px) {
    .form-blocks-for-asset .asset-detail .label,
    .asset-block-main-details .title .main-title{ word-break: break-all; }
    .d-block.d-multiple-blocks-small, .protection-options,
    .custom-size-setup-1{ width: 100%; }
    #main-dashboard{ padding-left: 0; }
    #d-header{
        width: 100%;
        height: auto;
        padding: 20px;
        z-index: 10000;
        -webkit-box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
    }
    #d-logo img{ height: 40px; }
    #d-logo{ display: inline-flex; vertical-align: middle; margin: 0; justify-content: flex-start; margin-left: 10px; }
    .d-wrapper{
        padding: 100px 20px 20px;
        row-gap: 20px;
    }
    .d-block.page-title-block h2{ margin-bottom: 0 ;}
    .filters-asset-risk-types{ gap: 5px; }
    .filters-asset-risk-types .filters-asset-risk-type{ width: calc(50% - 10px); }
    .d-main-asset-block .asset-block, .d-main-identity-block .identity-block{ min-width: 100%; max-width: 100%;}
}
@media only screen and (max-width:749px) {
    .d-block.layout-full-width{ gap: 20px; }
}
.form-filter-blocks{
    border:1px solid #3D3ABB;
    border-radius: 15px;
    padding: 37px 22px;
    margin-bottom: 20px;
    position: relative;
}
.form-filter-blocks h5{ margin-bottom: 20px; }

.form-filter-blocks .filter-close{
    position: absolute;
    top: 20px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
	z-index: 3;
    width: 15px;
}
.form-filter-blocks .filter-close svg path{
    fill:#000;
}

.form-actions-button{
    display: flex;
    column-gap: 20px;
}
.form-actions-button a{
    border: 1px solid #3D3ABB;
    color: #3D3ABB;
    font-size: 15px;
    font-weight: bold;
    padding: 5px 20px;
    display: inline-flex;
    align-items: center;
    column-gap: 5px;
    border-radius: 100px;
    text-decoration: none;
    min-width: 141px;
}
.form-actions-button{ margin-top: 30px; }
.form-actions-button a.apply-filter-btn{
    background-color: #3D3ABB;
    color:#fff;
}

.dashboard-custom-header{
    background: #fff;
    padding: 21px 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: none;
}
.dashboard-custom-header #d-logo{ margin: 0; }
.dashboard-custom-header .custom-page-closer{
    
    align-items: center;
    background: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    padding: 12px;
    position: relative;
    border: 1px solid #3D3ABB;
}
.custom-page-wrapper{
    padding: 70px 30px;
    display: none;
}
.custom-template-1 .dashboard-custom-header,
.custom-template-1 .custom-page-wrapper{ display: block; }

.custom-page-wrap-med{
    width: 560px;
    margin: 0 auto;
    color:#000;
    max-width: 100%;
}
.custom-page-wrap-med{ max-width: 100%; }
.custom-page-wrap-med > h3{ margin-bottom: 50px; }
.custom-page-wrapper .form-field .form-field-wrap,
.custom-page-wrapper .form-field{ margin-bottom: 20px; }
.custom-page-wrapper .form-field-label{
    margin-bottom: 10px;
}
.custom-page-wrapper .form-field.form-field-section .form-field-label{
    border-bottom: 1px solid #ececec;
    padding-bottom: 14px;
}
.custom-page-wrapper .form-field.form-field-section{ margin-bottom: 40px; }
.custom-page-wrapper .form-field.form-field-section.last{ margin-bottom: 20px;}
.custom-page-wrapper .form-field-label .desc{
    font-size: 14px;
    color: #8D9BA9;
}
.custom-page-wrapper .input-text{
    font-size: 17px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    width: 100%;
    padding: 18px 24px;
}
.custom-page-wrapper .form-field-wrap{
    width: 235px;
}
.profile-photo-holder{
    width: 103px;
    height: 103px;
    border-radius: 100%;
    background: #fafaff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.profile-photo-holder svg{ width: 44px; height: 36px; }
.profile-photo-holder .icon-camera{
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background: #D8D8F1;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    right: 0;
}
.profile-photo-holder .icon-camera svg{
    width: 22px;
    height: 33px;
}
.custom-page-wrapper  .next-section{ margin-bottom: 50px; }

.form-blocks-for-asset{
    border-radius: 4px;
    background: #fafaff;
    padding: 20px 60px 20px 20px;
    position: relative;
    display: block;
    margin-bottom: 20px;
}
.form-blocks-for-asset .flex-wrap{
    column-gap: 15px;
    justify-content: space-between;
}
.form-blocks-for-asset .asset-icon{
    background-color: #D8D8F1;
    width: 45px;
    height: 45px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-blocks-for-asset .asset-icon svg{
    width: 27px;
    height: 20px;
}
.form-blocks-for-asset .asset-icon svg path{ fill: #0E0F0C; }
.form-blocks-for-asset .asset-detail{ margin-right: auto; }
.form-blocks-for-asset .asset-detail .label{
    font-weight: bold;
    color:#000;
    font-size: 16px;
}

.form-blocks-for-asset .asset-detail .info{
    color:#8D9BA9;
    font-size: 14px;
}
.form-blocks-for-asset .input-checkbox{
    position: absolute;
    right: 24px;
    top:0;
    bottom: 0;
    display: flex;
    align-items: center;
}
.form-blocks-for-asset .input-checkbox input{ display: none; }
.form-blocks-for-asset .input-checkbox .input-checkbox-span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.form-blocks-for-asset .input-checkbox .input-checkbox-span svg{ opacity: 0; }
.add-new-asset-trigger.form-blocks-for-asset{ text-decoration: none; }
.add-new-asset-trigger.form-blocks-for-asset  .input-checkbox-span{
    border:0;
}
.add-new-asset-trigger.form-blocks-for-asset  .input-checkbox-span svg,
.form-blocks-for-asset .input-checkbox input:checked + .input-checkbox-span svg{ opacity: 1;}

.add-new-asset-trigger.form-blocks-for-asset {
    border-style: dashed;
    border-color: #3D3ABB;
    background-color: transparent;
    border-width: 1px;
}
.add-new-asset-trigger.form-blocks-for-asset .asset-icon{
    background-color: transparent;
    border:1px solid #3D3ABB;
    border-radius: 4px;
}
.add-new-asset-trigger.form-blocks-for-asset .asset-icon svg{
    width: 27px;
    height: 20px;
}
.add-new-asset-trigger.form-blocks-for-asset .asset-icon svg path{ fill: #3D3ABB; }
.custom-template-1 #main-dashboard{padding: 0;background-color: #fff; }
.form-blocks-for-assets{ min-height: 85px; }

#modal-loader svg{ display: none; }
#modal-loader.success svg {
    width: 120px;
    border-radius: 100%;
    height: 121px;
    color: #3D3ABB;
    display: block;
    opacity: 1;
    position: relative;
    border: 2px solid #3D3ABB;
    padding: 20px;
    margin: 0 auto;
    top: 40px;
}
#modal-loader.success svg path{
    fill: #3D3ABB;
}
.profile-photo-holder.selected-photo .icon-camera,
.profile-photo-holder.selected-photo svg{ display: none; }
.profile-photo-holder.selected-photo img{ max-width: 100%; }
.profile-photo-holder .account-icon{
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.accordion-icon { margin-right: 14px;}
.checkbox-switcher .icon-lock,
.accordion-icon span{
    width: 54px; height: 54px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D8D8F1;
}
.accordion-header{ position: relative; padding-right: 50px; cursor: pointer; margin: 20px 0; }
.accordion-header .icon-arrow{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.accordion-header .icon-arrow span{
    display: inline-flex;
    transform: rotate(90deg);transition: all .3s ease;
}
.accordion-header .flex-wrap{ align-items: center; }
.accordion-header .title{ font-size: 18px; color:#0A0E1A; line-height: 18px; font-weight: bold; }
.accordion-header .title .badge{
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    border-radius: 255px;
    background:rgba(61, 58, 187, 0.1);
    padding: 3px 12px;
    font-weight: 400;
}
.accordion-header .desc{
    font-size: 18px;
    color: rgba(10, 14, 24, 0.5);
}
.accordion-content{ max-height: 0px; overflow: hidden; transition: all .3s ease; }
.active .accordion-content{ max-height: fit-content; margin-bottom: 20px; }

.accordion-block{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) ;
}
.accordion-block.no-border-bottom{ border-bottom: 0; }

.active .accordion-header .icon-arrow span{
    transform: rotate(-90deg);
}
.accordion-block.auto-height{ min-height: auto; }
.accordion-block .icon-lock svg{
    width: 23px;
    height: 44px;
}
.accordion-block.company-settings .icon-lock svg{ width: 37px; }
.accordion-icon svg path{ fill:#0E0F0C ;}
.accordion-block .dashboard-forms { min-height: 1px; }
.accordion-block .dashboard-forms .dashboard-form-field-group{ width: 1074px; max-width: 100%; margin: 0; }
.accordion-block .dashboard-forms .dashboard-form-field-group.custom-width{ width: 100%; max-width: 100%; margin: 0; }
.accordion-block .dashboard-forms .dashboard-form-field-group.no-border-bottom{ border-bottom: 0;}
.accordion-block .dashboard-forms .dashboard-form-field-group.border-top{ border-bottom: 1px solid #E4E7EC; }

.checkbox-switcher{
    background: rgba(217, 217, 217, 0.15);
    border-radius: 15px;
    padding: 20px 25px;
    margin-bottom: 15px;
}
.checkbox-switcher .title{
    color:#0A0E1A;
    font-weight: bold;
    margin-left: 10px;
    font-size: 18px;
}


/* The switch - the box around the slider */
.checkbox-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .checkbox-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .checkbox-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .checkbox-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .checkbox-slider {
    background-color: #3D3ABB;
  }
  
  input:focus + .checkbox-slider {
    box-shadow: 0 0 1px #3D3ABB;
  }
  
  input:checked + .checkbox-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .checkbox-slider.round {
    border-radius: 34px;
  }
  
  .checkbox-slider.round:before {
    border-radius: 50%;
  }

  .classic-d-block .section-block-title.extra-section-on-notif{
    padding: 30px 20px 10px;
  }
  .classic-d-block .section-block-title h4{
    margin-bottom: 0;
    font-weight: bold;
    color:#0A0E1A;
  }
  .settings-plan-blocks { padding-left: 24px; }
  .settings-plan-blocks .section-block-title h4{ margin-top: 37px;}

.section-plan-details{
    padding: 20px 5px;
    color:#000;
    font-size: 16px;
}
.section-plan-details .section-plan-detail{
    display: flex; flex-wrap: wrap; column-gap: 28px; row-gap: 28px;
    margin-bottom: 20px;
}
.section-plan-details .section-plan-detail.last{ margin-bottom: 0;}
.policy-text a,
.section-plan-details .section-plan-detail a{
    color:#241FFF;
    text-decoration: none;
}
.accordion-block .table-row-setup .value-bar { column-gap: 16px; }
.accordion-block .table-row-setup .value-bar .bar{ width: 186px; height: 21px; }
.accordion-block .table-row-setup .value-bar .score{
    width: 50px;
    text-align: center;
}
.accordion-block .d-block-plan-block{
    padding-left: 10px;
    padding-right: 20px;
}
.upgrade-order-summary{
    margin-top: 12px;
    padding: 16px 18px;
    border-radius: 15px;
    border:1px solid #dfdfdf;
}
.dashboard-forms button .label{ margin-bottom: 0; }

.order-items-content{ padding: 10px 0; }
.order-items-content h4{ font-weight: 500; margin-bottom: 5px !important; }
.order-items-content ul{ list-style: none; margin: 0 0 20px; padding-left: 5px; }
.order-items-content ul li{
    color:#707070;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}
.order-items-content ul li svg{ display: inline-flex; align-items: center; margin-right: 5px;}

.policy-text{
    margin: 20px auto 10px;
    width: 366px;
    max-width: 100%;
}

.dashboard-btn{
    min-width: 162px;
    background: #3D3ABB;
    border: 1px solid #3D3ABB;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    display: inline-flex;
    border: 0;
    justify-content: center;
    border-radius: 15px;
    padding: 14px;
    line-height: 18px;
    min-width: 162px;
    cursor: pointer;
    text-decoration: none;
}

#show-more-alerts{ margin-top: 30px; }
#show-more-alerts.hide-on-first-load{ display: none; }
#d-main-identity-block{ padding-bottom: 50px; }
#d-main-identity-block.loaded{ padding-bottom: 0; }

.custom-page-wrap-large{ width: 100%; max-width: 1146px; margin: 0 auto;}


.eyes{
    background-repeat: no-repeat;
    background-position: center;
    display: none;
    width: 18px;
    height: 18px;
    background-image: url(../../assets/images/icon-eyes.svg);
    margin-left: 5px;
    cursor: pointer;
}
.asset-type-password .eyes{ display: inline-flex; }

.asset-title-search.flex-wrap .text{ display: none; }
.asset-type-password .asset-title-search.flex-wrap{ position: relative; z-index: 5;}
.asset-type-password .asset-title-search.flex-wrap .text{
    opacity: 1;
    display: block;
    position: absolute;
    cursor: pointer;
}
.asset-type-password .asset-title-search.flex-wrap .real-text{
    opacity: 0;
    cursor: pointer; }
.asset-type-password .asset-title-search.flex-wrap.active .real-text{ 
    display: inline-flex;
    opacity: 1;
}
.asset-type-password .asset-title-search.flex-wrap.active .text{ display: none; }
.asset-type-password .eyes:before{
    content: "";
    display: none;
    height: 2px;
    background-color: #85878d;
    transform: rotate(320deg);
    position: absolute;
    width: 19px;
    top: 12px;
}
.asset-type-password .active .eyes:before{ display: block; }

.d-gauge .custom-risk-text{ display: none; }

.d-gauge .risk-1-block .recharts-layer .recharts-layer .recharts-layer.recharts-pie-sector:first-of-type path,
.d-gauge .risk-2-block .recharts-layer .recharts-layer .recharts-layer.recharts-pie-sector:first-of-type path,
.d-gauge .risk-3-block .recharts-layer .recharts-layer .recharts-layer.recharts-pie-sector:first-of-type path{ fill: #659354; }

.large-score .risk-type.low-risk,
.d-alert-level.low-risk,
.usp-view-risk-area .risk-type.low,
.d-gauge.risk-1 .gauge-risk-level,
.d-gauge.risk-2 .gauge-risk-level,
.d-gauge.risk-3 .gauge-risk-level{ background-color: #659354;}

.d-gauge.risk-1 .custom-risk-text.r-low,
.d-gauge.risk-2 .custom-risk-text.r-low,
.d-gauge.risk-3 .custom-risk-text.r-low{
    display: inline-flex;
}
.d-gauge .risk-4-block .recharts-layer .recharts-layer .recharts-layer.recharts-pie-sector:first-of-type path,
.d-gauge .risk-5-block .recharts-layer .recharts-layer .recharts-layer.recharts-pie-sector:first-of-type path,
.d-gauge .risk-6-block .recharts-layer .recharts-layer .recharts-layer.recharts-pie-sector:first-of-type path,
.d-gauge .risk-7-block .recharts-layer .recharts-layer .recharts-layer.recharts-pie-sector:first-of-type path{ fill: #F5A02B; }

.large-score .risk-type.med-risk,
.d-alert-level.med-risk,
.usp-view-risk-area .risk-type.med,
.d-gauge.risk-4 .gauge-risk-level,
.d-gauge.risk-5 .gauge-risk-level,
.d-gauge.risk-6 .gauge-risk-level,
.d-gauge.risk-7 .gauge-risk-level{ background-color: #F5A02B; }

.d-gauge.risk-4 .custom-risk-text.r-med,
.d-gauge.risk-5 .custom-risk-text.r-med,
.d-gauge.risk-6 .custom-risk-text.r-med,
.d-gauge.risk-7 .custom-risk-text.r-med{
    display: inline-flex;
}

.d-gauge .risk-8-block .recharts-layer .recharts-layer .recharts-layer.recharts-pie-sector:first-of-type path,
.d-gauge .risk-9-block .recharts-layer .recharts-layer .recharts-layer.recharts-pie-sector:first-of-type path,
.d-gauge .risk-10-block .recharts-layer .recharts-layer .recharts-layer.recharts-pie-sector:first-of-type path{ fill: #C92E2A; }

.large-score .risk-type.high-risk,
.d-alert-level.high-risk,
.usp-view-risk-area .risk-type.high,
.d-gauge.risk-8 .gauge-risk-level,
.d-gauge.risk-9 .gauge-risk-level,
.d-gauge.risk-10 .gauge-risk-level{ background-color: #C92E2A; }

.d-gauge.risk-8 .custom-risk-text.r-high,
.d-gauge.risk-9 .custom-risk-text.r-high,
.d-gauge.risk-10 .custom-risk-text.r-high{
    display: inline-flex;
}


.large-score .risk-type,
.usp-view-risk-area .risk-type.high,
.usp-view-risk-area .risk-type.med,
.usp-view-risk-area .risk-type.low{ color:#fff; }

.livefeed .risk-record-block .risk-score-and-type .risk-score{ font-size: 17px; line-height: 1.2;}

@media only screen and (max-width:1300px) {
    .usp-view-report-bottom{ width: 100%; }
    .usp-view-report-area.size-1{ flex-wrap: wrap;}
    .usp-view-risk-area.size-1{ flex: 0 0 120px; }
}

@media only screen and (max-width:900px) {

    .back-to-previous-page{ margin-top: -15px; }
    
.back-to-previous-page.report-link{ margin-top:0; position: absolute; width: auto; right: 30px; top: 115px; margin-top: 0; }

.back-to-previous-page a{
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #3D3ABB;
    border-radius: 146px;
    border-radius: 146px;
    padding: 2px 12px 2px 12px;
    gap: 7px;
    font-weight: 500;
    line-height: 32px;
    top: 115px;
}
.back-to-previous-page a svg{ width: 15px;}
}

@media only screen and (max-width:749px) {
    .d-block.email-report-page .wrap{
        padding: 30px;
    }
    .d-block.email-report-page .d-block-header{ gap: 10px; margin-bottom: 10px; }
    #page-title-bar,
    .d-block.email-report-page .d-block-header .value{ font-size:  17px !important;}
    .risk-record-block .risk-score-and-type .risk-score{ font-size: 17px; line-height: 1.2;}
    .d-block.email-report-page h2{ margin-bottom: 20px; }
    .credit-last-scan .label{ font-size: 18px; margin-bottom: 10px; line-height: 1.5em; }
    .large-score .value{ font-size: 40px; }
    .credit-more-action-buttons{ padding: 0 0 20px; }
    .d-block.email-report-page .d-block-header .report-number-and-scan .report-number{ display: none; }
}
@media only screen and (max-width:480px) {
    .gauge-risk-level{ margin-bottom: 15px; }
    .usp-view-report-area .usp-view-report-top{ gap: 10px; }
    .d-main-identity-block .identity-block, .d-main-asset-block .asset-block,
    .user-assets-protection-single{ padding: 15px; }
    .d-block.d-asset-protection-block .form-actions .form-custom-dropdown{ flex: 0 0 100%; }
    .form-custom-dropdown select{ width: 100%; }
    .classic-d-block .wrap, .d-block.d-gauge.layout-3 .wrap, .d-block.layout-2.d-asset-protection-block .wrap{
        padding: 20px;
        row-gap: 10px;
    }
    .asset-block-other-details, .asset-block-main-details{ gap: 5px; }
    .risk-record-block{padding: 0; }
    .risk-record-block .risk-record-name svg{ display: block; }
    .dashboard-forms .dashboard-form-fields.col-gap-20 .one-half{ width: 100%; }
}
@media only screen and (max-width:400px) {
    .back-to-previous-page a{ font-size: 12px;}
    .modal-content{ max-width: 100%; }
    .fadeIn .modal-content{
        transform: translate(-50%, 80px);
        border-radius: 0;
        border: 0;
        min-height: calc(100% - 80px);
    }
    .modal-close {
        right: 8px;
        top: 8px;
    }
    .modal-bg{ background: transparent; }
    .add-assets-actions-list li a{ font-size: 12px; }
    .modal-content h3,
    .add-assets-actions-list{ margin-bottom: 20px; }

    .d-block.layout-1{ padding: 20px;}
    .d-block.layout-1.score-graph-1{ padding: 20px 0 20px;}
    .d-block.layout-1.score-graph-1 h3{ padding: 0 20px;}
    .d-block.score-graph-1{ width: 100%; }
    .d-block.score-graph-1 .recharts-wrapper{ width: 100% !important;}
    .db-gauge-score{ font-size: 30px; }
    
    .credit-last-scan .label {
        font-size: 16px;
    }
    .d-block-plan-block + .d-block-plan-block{ margin-top: 20px; }
}
@media only screen and (max-width:380px) {
    .usp-view-report-area .usp-view-report-top .label{
        padding-right: 40px;
        word-break: break-word;
    }
    .usp-view-report-area .usp-view-report-top .title{
        font-size: 14px;
    }
}
@media only screen and (max-width:250px) {
    .modal-close{ padding: 6px;}
}