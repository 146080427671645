@font-face {
	font-family: 'Aspekta';
	src: url('./assets/fonts/Aspekta-300.woff') format('woff2'),
		url('./assets/fonts/Aspekta-300.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Aspekta';
	src: url('./assets/fonts/Aspekta-400.woff2') format('woff2'),
		url('./assets/fonts/Aspekta-400.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Aspekta';
	src: url('./assets/fonts/Aspekta-500.woff2') format('woff2'),
		url('./assets/fonts/Aspekta-500.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Aspekta';
	src: url('./assets/fonts/Aspekta-600.woff2') format('woff2'),
		url('./assets/fonts/Aspekta-600.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Aspekta';
	src: url('./assets/fonts/Aspekta-700.woff2') format('woff2'),
		url('./assets/fonts/Aspekta-700.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: 'Aspekta';
	src: url('./assets/fonts/Aspekta-800.woff2') format('woff2'),
		url('./assets/fonts/Aspekta-800.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
  }
  
  
  *,
  *::before,
  *::after {
	box-sizing: border-box;
	margin:0;
	padding:0;
  }
  html {
	scroll-behavior: smooth;
  }
  body{
	font-family: 'Aspekta', sans-serif;
	font-weight:300;
	color:#8D9BA9;
	font-size: 18px;
	line-height:1.4em;
  }
  
  h2.big,
  h1{
	  font-size: 96px;
	  line-height: 1.1em;
	  font-weight:300;
	  color:#000;
  }
  h1.med{
	  font-size: 64px;
  }
  h2{
	  font-size: 48px;
	  line-height: 1.2em;
	  font-weight:300;
	  color:#000;
  }
  h2.med{
	  font-size: 40px;
  }
  h3{
	  font-size: 32px;
	  line-height: 1.3em;
	  font-weight:300;
	  color:#000;
  }
  h4{
	  font-size: 24px;
	  line-height: 1.3em;
	  font-weight:300;
	  color:#000;
  }
  h5{
	  font-size: 20px;
	  line-height: 1.3em;
	  font-weight:500;
	  color:#000;
  }
  h6{
	  font-size: 18px;
	  line-height: 1.3em;
	  font-weight:500;
	  color:#000;
  }
  .gradient-text span{
	  background-image: linear-gradient(45deg, #9077F7, #56C9FB, #9077F7);
	  background-size: 100%;
	  -webkit-background-clip: text;
	  -moz-background-clip: text;
	  -webkit-text-fill-color: transparent; 
	  -moz-text-fill-color: transparent;
	  font-weight: 600;
  }
  .intro{
	  font-size: 24px;
	  font-weight:600;
	  color:#6967EF;
	  min-height:25px;
  }
  p{ line-height:1.4em; }
  p.big{
	  font-size: 29px;
  }
  .med-text,
  p.med{
	  font-size:20px;
  }
  p.small{
	  font-size: 16px;
  }
  .section{
	  padding-left:30px;
	  padding-right:30px;
	  overflow:hidden;
  }
  .large-padding-section{
	  padding-top: 120px;
	  padding-bottom:120px;
  }
  .med-padding-section{
	  padding-top: 100px;
	  padding-bottom:100px;
  }
  .small-padding-section{
	  padding-top: 80px;
	  padding-bottom:80px;
  }
  .text-uppercase{ text-transform: uppercase;}
  .text-black{ color:#000; }
  .text-dark{ color:#222; }
  .text-blue{ color:#6967EF; }
  .text-white{ color:#ffffff; }
  .mb-30{ margin-bottom: 30px; }
  .mb-40{ margin-bottom: 40px; }
  .mb-50{ margin-bottom: 50px; }
  .col-gap-15{ column-gap:15px; row-gap: 15px; }
  .col-gap-30.btn-flex-holder,
  .col-gap-30{ column-gap:30px; row-gap: 30px; }
  .col-gap-48{ column-gap:48px; row-gap: 48px; }
  .col-gap-30 .one-third{ width: calc(33.33% - 20px); }
  .col-gap-48 .one-third{width: calc(33.33% - 45px);}
  .col-gap-48 .one-half{width: calc(50% - 40px);}
  .flex-wrap-block.col-gap-30 .one-half{ width: calc(50% - 20px); }
  .flex-wrap{ display:flex; }
  .flex-wrap-block{ flex-wrap: wrap; }
  .flex-align-center{ align-items:center; }
  .flex-justify-between{justify-content: space-between;}
  .flex-justify-center{justify-content: center;}
  .flex-justify-end{justify-content: end;}
  .flex-direction-column{ flex-direction: column; }
  .img-as-block{ display:block; }
  .img-auto{ margin-left:auto; margin-right: auto; }
  .one-whole{ width:100%; }
  .one-half{ width:50%; }
  .one-third{ width: 33.33%; }
  .two-third{ width: 66.66%; }
  .text-center{text-align:center; }
  .text-left{text-align:left; }
  .text-right{text-align:right; }
  .page-wrapper{
	  width:100%;
	  max-width:1352px;
	  margin-left: auto;
	  margin-right:auto;
  }
  .wrapper{
	  width:100%;
	  max-width:1615px;
	  margin-left: auto;
	  margin-right:auto;
  }
  #header{ padding: 32px 30px; border-bottom:1px solid #DFDFDF; }
  #header.no-border{ border-bottom: 0; }
  .header-logo{ column-gap: 77px; }
  #nav-menu ul{
	  list-style:none;
	  column-gap: 50px;
  }
  .with-login-link a,
  #nav-menu ul a{
	  font-weight:300;
	  color:#000;
	  text-decoration:none;
	  font-size:20px;
	  line-height:1em;
  }
  #nav-icon{ display:none; }
  .btn{
	  border:1px solid #3D3ABB;
	  padding: 18px 29px;
	  color:#000;
	  font-size:20px;
	  line-height:20px;
	  font-weight:500;
	  text-decoration:none;
	  cursor:pointer;
	  border-radius: 255px;
	  align-items: center;
	  column-gap: 10px;
	  justify-content: center;
	  display: inline-flex;
  }
  .btn.blue-btn{
	  background:#3D3ABB;
	  color:#fff;
  }
  .btn.white-btn{
	  border-color:#fff;
	  color:#fff;
  }
  .btn.black-btn{
	  border-color:#000000;
	  color:#000000;
  }
  .btn.red-btn{
	  border-color:#FB3838;
	  background-color: #FB3838;
	  color:#fff;
  }
  .btn.blue-btn svg{ display:inline-flex; align-items: center; margin-left:10px; }
  .btn.blue-btn svg path{ fill: #fff; }
  .btn-flex-holder{display:flex;flex-wrap:wrap;column-gap: 16px;row-gap:16px;}
  .btn-flex-holder.center-btn{ justify-content: center; }
  .btn.full-btn{ width:100%; }
  .with-login-link .btn{ border-radius:4px; }
  .btn.btn-back,
  .btn.blue-btn.btn-next{
	  padding: 18px;
	  font-size: 20px;
	  font-weight: bold;
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  column-gap: 10px;
  }
  .btn.btn-back{
	  border:1px solid #000;
	  background:transparent;
  }
  .btn.btn-back svg{
	  transform:rotate(-180deg);
  }
  .btn.small{
	padding: 10px;
	font-size: 14px;
  }

  .hero-banner{
	  position: relative;
	  display:flex;
	  min-height: 731px;
  }
  .hero-banner .hero-image{
	  position:absolute;
	  top:0;
	  right:0;
  }
  .hero-banner .hero-image img{ max-width:100%; }
  .hero-banner .hero-content{ width:100%; max-width: 58%; padding-top: 100px;padding-bottom:100px; }
  .hero-banner h1{
	  margin-bottom: 40px;
  }
  .hero-banner p.big{color:#8D9BA9;margin-bottom: 48px; line-height:1.3em; }
  .hero-banner .btn-flex-holder{margin-bottom: 45px;}
  .hero-banner .intro{ margin-bottom: 15px; }
  .hero-banner h2.big{ margin-bottom: 15px; }
  .in-partnership .block-title{
	  color:#97A9BA;
	  font-size:16px;
	  margin-bottom: 8px;
  }
  .logos{ column-gap: 20px; row-gap: 20px; }
  
  .small-wrapper-auto{ width:100%; max-width: 675px; margin-left: auto; margin-right: auto; }
  .small-wrapper{ width:100%; max-width: 720px; }
  .small-page-content-block{
	  width:100%; max-width: 840px; margin-left: auto; margin-right: auto;
  }
  .medium-page-content-block{
	  width:100%; max-width: 1204px; margin-left: auto; margin-right: auto;
  }
  
  .has-border-top .wrapper{
	  border-top:1px solid #DFDFDF;
  }
  
  .flexbox .wrapper{
	  display: flex;
	  flex-direction:column;
	  row-gap: 74px;
	  padding: 74px 0;
  }
  .flexbox.flexbox-spacing-2 .wrapper{ row-gap: 32px; }
  .small-padding-top.flexbox .wrapper{
	  padding-top: 22px;
  }
  .flexbox .content-block{
	  display:flex;
	  flex-direction:column;
	  row-gap: 16px;
  }
  .flexbox .content-block{ width: 100%; max-width: 798px; }
  .flexbox .content-block.width-2{ width: 100%; max-width: 665px; }
  .flexbox .full-width.content-block{ width:100%; max-width:100%; }
  .position-relative{ position: relative; }
  .box-style-1{
	  background-size:cover;
	  border-radius: 30px;
	  color:#fff;
	  padding: 52px 48px;
	  display: flex;
	  align-items: flex-end;
  }
  .box-style-1 .styled-content{
	  width: 100%;
	  background: rgba(255,255,255,0.2);
	  border-radius:15px;
	  padding: 34px 28px;
	  backdrop-filter: blur(5px);
  }
  .box-style-1 .styled-content h2,
  .box-style-1 .styled-content h3,
  .box-style-2 .styled-content h2,
  .box-style-2 .styled-content h3{
	  color:#fff;
	  width:100%;
  }
  
  .custom-block-style-1{
	  min-height: 571px;
	  background:url('./assets/images/section-2-bg.jpg') no-repeat center;
  }
  .custom-block-style-4{
	min-height: 551px;
	background:url('./assets/images/section-6-bg.jpg') no-repeat center;
	background-size: cover;
	border-radius: 30px;
	color:#fff;
	display: flex;
    justify-content: center;
	padding: 30px;
  }
  .custom-block-style-4 .warning-icon{
    margin: -105px auto 10px;
    display: block;
  }
  .custom-block-style-3{
	  background: rgba(255,255,255,0.2);
	  backdrop-filter: blur(5px);
  }
  
  .box-style-2{
	  background-size:cover;
	  border-radius: 30px;
	  color:#fff;
	  display: flex;
  }
  .box-style-2 h2,
  .box-style-2 h4{
	  color:#fff;
  }
  .box-style-2 .styled-content{ width:100%; }
  .box-style-2 .styled-content .content-area{
	  padding: 52px 0 52px 90px;
	  min-height: 579px;
	  width: calc(100% - 676px);
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  row-gap: 24px;
  }
  .custom-block-style-1 .styled-content{
	  max-width: 913px;
  }
  .custom-block-style-1 .styled-content h3{
	  margin-bottom: 58px;
	  max-width:600px;
  }
  .custom-block-style-1 .styled-content .number-block{
	  margin-bottom:5px;
	  font-size: 40px;
	  line-height: 1em;
	  font-weight:500;
  }
  .number-grid .one-third{
	  border-left:2px solid #6967EF;
	  padding-left: 20px;
  }
  .flexbox .btn-flex-holder{ margin-top:10px; }
  
  .grid-icon-boxes .grid-icon-box{
	  border: 1px solid #DEDEDE;
	  margin-bottom: 32px;
	  padding: 40px;
  }
  .grid-icon-boxes .grid-icon-box .icon{
	  margin-bottom: 24px;
  }
  .grid-icon-boxes .grid-icon-box h3{ margin-bottom: 20px; }
  .grid-icon-boxes .grid-icon-box .learn-more{ margin-top: 50px; }
  .grid-icon-boxes .grid-icon-box .learn-more a{
	  text-decoration:none;
	  font-size: 20px;
	  color:#000;
  }
  .grid-icon-boxes .grid-icon-box .learn-more a svg{ margin-left: 5px; }
  
  .custom-block-style-2{
	  min-height: 571px;
	  background:url('./assets/images/section-4-bg.jpg') no-repeat center;
	  background-size: cover;
  }
  .custom-block-style-2.bg-1{
	  background:url('./assets/images/section-4a-bg.jpg') no-repeat center;
	  background-size: cover;
  }
  .custom-block-style-2 .styled-content{
	  max-width: 724px;
  }
  .custom-block-style-2 .styled-content h2{
	  margin-bottom: 24px;
	  max-width:600px;
  }
  .custom-block-style-2 .styled-content p.big{
	  margin-bottom: 24px;
  }
  .small-wrap{ width: 100%; margin-left: auto; margin-right: auto; max-width:1095px;}
  .page-wrapper-1170{
	  width:100%;
	  max-width:1140px;
	  margin: 0 auto;
  }
  
  .content-setup > *,
  .small-wrap > *{ margin-bottom: 16px; }
  .icon-grid{ row-gap: 48px; }
  .icon-grid .content-setup{ width: 100%; max-width:480px; margin: 0 auto; }
  .offset-content-1{ margin-top: 55px; }
  .slideshow-master .slick-slide > div{
	  width: 1344px; max-width: 100%;
	  padding-right: 96px;
  }
  .custom-slide-setup{
	  width:100%;
	  max-width:1344px;
	  margin-right: 96px;
  }
  .custom-slide-wrapper{
	  width:100%;
	  height:513px;
	  background-size:cover;
	  border-radius: 30px;
	  color:#fff;
	  padding: 72px;
	  background:url('./assets/images/slider-bg.jpg') no-repeat center;
  }
  .the-slider-icon-boxes,
  .slider-block{ overflow:hidden; }
  .the-slider-icons .slick-list,
  .slider-block .slick-list{ overflow:visible !important; }
  .slider-area-wrap .slick-dots{position: absolute;bottom: 30px;width: 100%;display: flex !important;justify-content: center;list-style: none;column-gap: 10px;flex-wrap: wrap;margin: 0;}
  .slider-area-wrap{ position: relative; }

  .landing-page-v2.v2-r2 .slick-dots button,
  .slider-area-wrap .slick-dots button{
	  width: 40px;
	  height: 5px;
	  background: rgba(255,255,255,0.3);
	  border-radius: 11px;
	  border: 0;
	  overflow: hidden;
	  text-indent: -999px;
	  cursor:pointer;
  }
  .landing-page-v2.v2-r2 .slick-dots li{ margin: 0; padding: 0;}
  .landing-page-v2.v2-r2 .slick-dots{
    display: flex !important;
    flex-wrap: wrap;
    list-style: none;
    margin: 40px 0;
    justify-content: center;
    column-gap: 10px;
}
  .landing-page-v2.v2-r2 .slick-dots button{
	background: rgba(34,43,52,0.3);
}
.landing-page-v2.v2-r2 .slick-dots .slick-active button{ background: rgba(0,0,0,1); }
  .slider-area-wrap .slick-dots .slick-active button{ background: rgba(255,255,255,1); }
  .slider-testimonial{
	  height: 100%;
	  display: flex;
	  flex-direction: row-reverse;
	  column-gap: 73px;
  }
  .slider-testimonial .image{
	  display: flex;
	  align-items: center;
  }
  .slider-testimonial .content h4{ color:#fff; }
  .slider-testimonial .content{
	  display: flex;
	  align-items: flex-start;
	  flex-direction: column;
	  justify-content: flex-start;
	  column-gap: 25px;
	  row-gap: 25px;
	  color: #fff;
  }
  .loaded{
	  -webkit-transition: all 150ms ease-in;
	  -moz-transition: all 150ms ease-in;
	  -o-transition: all 150ms ease-in;
	  -ms-transition: all 150ms ease-in;
	  transition: all 150ms ease-in;
  }
  .radial-bg.loaded{
	  background:url('./assets/images/radial.jpg') no-repeat bottom right;
	  background-size:cover;
  }
  .radial2-bg.loaded{
	  background:url('./assets/images/radial-2.jpg') no-repeat bottom right;
	  background-size:cover;
  }
  .radial3-bg.loaded{
	  background:url('./assets/images/radial-3.jpg') no-repeat bottom right;
	  background-size:cover;
  }
  .radial4-bg.loaded{
	  background:url('./assets/images/radial-4.jpg') no-repeat top right;
  }
  .radial5-bg.loaded{
	  background:url('./assets/images/radial-5.jpg') no-repeat top right;
	  background-size: cover;
  }
  .radial6-bg.loaded{
	  background:url('./assets/images/radial-6.jpg') no-repeat bottom center;
  }
  .radial7-bg.loaded{
	  background:url('./assets/images/radial-7.jpg') no-repeat bottom center;
  }
  .linear-bg.loaded{
	  background:url('./assets/images/linear-bg.jpg') no-repeat top center;
	  background-size:cover;
  }
  .gradient-border{
	  background: linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 2px) calc(100% - 2px) no-repeat,
	  linear-gradient(90deg, #9077f7 0%, #56c9fb 46%, #9077f7 100%);
	  border-radius: 0px;
	  padding: 1px;
	  box-sizing: border-box;
  }
  .gradient-bg.loaded{
	  background:url('./assets/images/gradient-bg.jpg') no-repeat center;
	  background-size:cover;
  }
  .switch-style-1{
	  display:flex;
	  column-gap: 9px;
	  padding: 9px;
	  border-radius: 43px;
	  border:1px solid #DFDFDF;
  }
  .switch-style-1 span{
	  min-width: 124px;
	  padding: 20px 24px;
	  display:block;
	  color:#000;
	  font-weight: 600;
	  border-radius: 48px;
  }
  .switch-style-1 span.active{
	  color:#fff;
	  background-color:#32A4BD;
  }
  .switch-style-container{
	  display: flex;
	  align-items:center;
	  justify-content:center;
  }
  .price-table.flexbox .wrapper{
	  row-gap: 38px;
  }
  .price-table-section{
	  column-gap: 32px;
	  display:flex;
	  justify-content: center;
  }
  .price-table-section .price-table-block{
	  padding: 40px;
	  border:1px solid #6967EF;
	  width: 33.33%;
	  display:flex;
	  flex-direction:column;
	  row-gap: 25px;
	  background: #fff;
	  border-radius: 15px;
  }
  .price-table-section .price-table-block.yellow-border{
	  border:1px solid #B4E724;
  }
  .price-table-section .price-table-block.featured:before{
	  width: 67px;
	  height: 66px;
	  content: "";
	  background: url('./assets/images/featured-shape.png') no-repeat bottom center;
	  position: absolute;
	  display: block;
	  top: 0;
	  right: 34px;
  }
  .price-table-section .price-table-block.featured{
	  border:3px solid #6967EF;
	  position:relative;
	  
  }
  .price-table-section .price-table-title{
	  font-size: 36px;
	  color:#000;
	  font-weight:500;
	  line-height:36px;
  }
  .price-table-section .price-table-block.featured .price-table-title { color:#6967EF; }
  .price-table-section .price-table-price .price{
	  font-size: 64px;
	  line-height:64px;
	  color:#000;
	  font-weight:500;
  }
  .price-table-section .price-table-price .month{
	  font-size: 24px;
	  color:#000;
	  font-weight:300;
  }
  .price-table-section .price-table-price .month.text-blue{ margin-left: 5px ; color: #6967EF !important;}
  .price-table-section .price-table-btn{
	  display:flex;
	  flex-direction:column;
  }
  .price-table-section .price-table-btn a{
	  width:100%;
	  max-width:100%;
	  text-align:center;
	  border-radius: 255px;
  }
  .price-table-list ul { list-style:none; color:#000; margin-top: -22px; }
  .price-table-list ul li{
	  border-bottom:1px solid #63717E;
	  position:relative;
  }
  .price-table-list ul li .accord-item{
	font-size:20px;
	font-weight:500;
	padding: 22px 20px 22px 0;
	cursor: pointer;
  }
  .price-table-list ul li .accord-item.no-click{
	cursor:default;
  }
  .price-table-list ul li .accord-item:after{
	  width:15px;
	  height:15px;
	  background:#B4E724;
	  border-radius:100%;
	  content:"";
	  position: absolute;
	  right: 0;
	  top: 25px
  }
  .price-table-list ul li .accord-content{
	padding: 0 0 30px 0
  }
  .price-table-list ul li .accord-content p{ margin-bottom: 20px; }
  .price-table-list ul li .accord-content p:last-of-type{ margin-bottom: 0; }
  .price-table-list ul li .accord-item.black-circle:after,
  .price-table-list ul li.black-circle:after{
	  background:#000;
  }
  .price-table-list ul li:last-child{
	  border-bottom:0;
  }
  .price-table-section .desc{ font-size: 16px; margin-top: 5px; }
  .secure-images .first { margin-bottom: 24px; }
  .safe-secure-content{
	  width:100%;
	  max-width: 914px;
	  margin: 0 auto;
	  font-size: 14px;
  }
  
  .faq-accord{
	  width:100%;
	  max-width: 969px;
	  margin: 0 auto;
  }
  .faq-accord.wider{ max-width: 1074px; }
  .faq-block{
	  border-bottom:1px solid #DFDFDF;
	  padding: 26px 0;
  }
  /*
  .faq-block .faq-content{ display:none; margin-top: 30px; }
  .faq-block.active .faq-content{ display:block; }
  */
  
  .faq-block .faq-content{
	  font-size: 0;
	  margin: 0;
	  opacity: 0;
	  padding: 0;
	  /* fade out, then shrink */
	  transition: opacity 300ms,
				  font-size 300ms 150ms,
				  margin 300ms 150ms,
				  padding 300ms 150ms;
	  }
	  .faq-block .faq-content ul{
		display: none;
	  }
	  .faq-block.active .faq-content ul{
		display: block;
		margin: 0 0 0 30px;
	  }
  .faq-block.active .faq-content{
	  font-size: 18px;
	  opacity: 1;
	  margin-top: 20px;
	  transition: font-size 150ms,
	  margin 150ms,
	  padding 150ms,
	  opacity 300ms 150ms;
  }
  .faq-block .faq-title{
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  column-gap:30px;
	  cursor:pointer;
  }
  .faq-block .faq-title .faq-icon{ flex: 0 0 20px; }
  .faq-block.active .faq-title .faq-icon{ transform: rotate(-180deg); }
  .overflowing-image-1{ position: absolute; bottom: 0; right: 0; }
  
  #main-footer .top-footer{
	  padding-top: 64px;
	  padding-bottom:64px;
  }
  .top-footer .footer-menus{ margin-right: auto;}
  .top-footer .main-flex{ column-gap: 7%; }
  .footer-menu-flex{ column-gap: 64px; }
  .footer-menu h5{ margin-bottom: 35px; }
  .footer-menu ul{ list-style:none; }
  .footer-menu ul li{ margin-bottom:20px; }
  .footer-menu ul li a{ color:#97A9BA; text-decoration:none; font-size: 16px; }
  .footer-contacts{
	  text-align:right;
  }
  .footer-contacts > div{ margin-bottom: 24px; }
  .footer-contacts a{
	  color:#222B34;
	  text-decoration:none;
	  font-size:24px;
  }
  #main-footer .bottom-footer{
	  border-top:1px solid #DFDFDF;
	  padding-top: 32px;
	  padding-bottom:32px;
  }
  #main-footer .bottom-footer .wrapper{
	  display:flex;
	  flex-direction: row-reverse;
	  justify-content: space-between;
	  align-items: center;
	  flex-wrap: wrap;
  }
  #main-footer .copyright{
	  color:#000000;
	  font-size:16px;
	  width: 100%;
	  padding-top: 30px;
	  display: flex;
	  justify-content: space-between;
  }
  .page-banner{ position:relative; }
  .page-banner-image{
	  position: absolute;
	  top:0;
	  right:0;
  }
  .page-banner.page-banner-style-1 .page-banner-content{
	  min-height: 874px;
	  row-gap: 37px;
  }
  .blog-post-header,
  .page-banner .page-banner-content{
	  position:relative;
	  z-index: 3;
	  flex-direction: column;
	  justify-content: center;
	  width:50%;
	  row-gap: 24px;
	  display:flex;
  }
  .page-banner.page-banner-no-image .page-banner-content{ width:100%; }
  .page-banner .intro{}
  .page-banner h1{}
  .page-banner .page-wrapper{ display: flex; }
  .page-banner p{ color:#000; }
  .image-with-text-block{
	  display:flex;
	  column-gap: 84px;
	  padding: 141px 0;
	  align-items: center;
  }
  .image-with-text-block .image img{ border-radius: 30px; }
  
  .page-content-block{
	  display:flex;
	  flex-direction:column;
	  row-gap: 16px;
  }
  .page-content-block.med-gap{
	  row-gap: 30px;
  }
  .page-content-block.large-gap{
	  row-gap: 36px;
  }
  blockquote{ padding-left: 50px; }
  blockquote h4{ margin-bottom: 10px;}
  blockquote p + h4{ margin-top: 32px; }
  .content-first .image-with-text-block{
	  flex-direction: row-reverse;
  }
  .three-column-icon-boxes .the-boxes,
  .three-column-icon-boxes .small-page-content-block{ margin-bottom:100px; }
  .three-column-icon-boxes .the-boxes{ column-gap:16px; }
  .three-column-icon-boxes .the-boxes .the-box{
	  border:1px solid #6967EF;
	  padding: 40px;
	  width:33.33%;
  }
  .the-box .icon,
  .the-box h3{ margin-bottom:15px; }
  
  .three-column-icon-boxes .page-wrapper{ border-top: 1px solid #DFDFDF; }
  
  .the-slider-icons .the-box{ width:100%; max-width: 350px; margin-right: 70px; }
  
  .faded-sliders{
	  position:relative;
  }
  .faded-sliders:before{
	  content:"";
	  height:100%;
	  width: 232px;
	  background:url('./assets/images/fade-left.png') repeat-y top left;
	  position:absolute;
	  top:0;
	  left: -30px;
	  display: block;
	  z-index: 3;
	  display:block;
  }
  .faded-sliders:after{
	  content:"";
	  height:100%;
	  width: 232px;
	  background:url('./assets/images/fade-right.png') repeat-y top left;
	  position:absolute;
	  top:0;
	  right: -30px;
	  display: block;
	  z-index: 3;
	  display:block;
  }
  
  .blog-categories{
	  display:flex;
	  flex-wrap:wrap;
	  column-gap: 8px;
	  row-gap: 8px;
  }
  .blog-categories a{
	  border: 1px solid #3d3abb;
	  border-radius: 24px;
	  font-size: 14px;
	  color: #000;
	  font-weight: 500;
	  text-decoration: none;
	  padding: 12px 16px;
  }
  .blog-categories a.active{
	  background:#3d3abb;
	  color:#fff;
  }
  
  .blog-posts h3{
	  margin-bottom: 48px;
  }
  .blog-posts .blog-posts-block{ display: flex; flex-wrap:wrap; column-gap: 84px; row-gap: 48px; }
  .blog-posts  .blog-post{
	  position: relative;
	  width: calc(33% - 55px);
	  padding-bottom:40px;
  }
  .blog-posts  .blog-post .blog-post-image img{ border-radius: 6px; max-width:100%; }
  .blog-posts  .blog-post .blog-post-image { margin-bottom:24px; }
  .blog-posts  .blog-post .blog-post-categories {
	  text-transform:uppercase;
	  color:#6967EF;
	  font-size:14px;
	  font-weight: 600;
  }
  .blog-posts  .blog-post .blog-post-categories ,
  .blog-posts  .blog-post h4,
  .blog-posts  .blog-post p{
	  margin-bottom:16px;
  }
  .blog-posts  .blog-post h4,
  .blog-posts  .blog-post a{
	  color:#000;
  }
  .blog-posts  .blog-post .read-more{ position: absolute; bottom:0; left:0; }
  .blog-posts  .blog-post .read-more a{ text-decoration:none; }
  .blog-posts  .blog-post .read-more a svg{ margin-left: 5px; }
  
  .blog-post-header{
	  width:100%;
	  max-width:1140px;
	  margin: 0 auto 70px;
  }
  .blog-post-header .blog-categories{
	  justify-content: center;
  }
  .blog-post-header .intro a{
	  text-transform:uppercase;
	  color:#6967EF;
	  text-decoration:none;
  }
  .blog-post-content .blog-post-image img{ border-radius: 30px; }
  .blog-post-content .blog-post-image { margin-bottom: 40px; }
  
  .blog-post-content{
	  width:100%;
	  max-width:798px;
	  margin: 0 auto;
  }
  .blog-post-content > *{
	  margin-bottom: 25px;
  }
  .blog-post-share{ display: flex; column-gap: 40px; margin-top: 40px; }
  
  .meet-the-team-block > div{
	  border:1px solid #6967EF;
	  flex-grow: 1;
  }
  
  .meet-the-team-block .one-half{
	  padding: 48px;
  }
  
  .meet-the-team-block .one-third{
	  padding: 40px;
	  text-align:center;
  }
  .team-member-block .image img{ border-radius: 33px; }
  .team-member-block{ display: flex; flex-direction:column; row-gap: 16px; color:#000; }
  .team-member-block .position{ color:#6967EF; }
  .meet-the-team .btn-flex-holder{ margin-bottom: 24px; }
  .send-message-inquiry{
	  padding: 48px;
	  border:1px solid #6967EF;
	  display:flex;
	  flex-direction:column;
	  row-gap: 24px;
	  padding-right: 383px;
	  position:relative;
  }
  .send-message-inquiry .send-message-contacts{
	  position: absolute;
	  right: 48px;
	  top:48px;
	  width: auto;
  }
  .send-message-inquiry .send-message-contacts > div{ margin-bottom: 24px; }
  .send-message-inquiry .send-message-contacts > div:last-child{ margin-bottom: 0; }
  .contact-email{ margin-bottom: 10px; }
  .contact-email a,
  .contact-phone a,
  .send-message-inquiry .send-message-contacts a{
	  color:#6967EF;
	  font-size:24px;
	  text-decoration:none;
  }
  .form-block .form-field .input-text{
	  width:100%;
	  padding:15px 18px;
	  border:1px solid rgba(105,103,239,0.5);
	  font-size:20px;
	  border-radius: 4px;
  }
  
  .form-block .form-field.show-password .input-text{ padding-right: 50px; }
  .form-block .form-field .input-text::placeholder {
	color: #C3D5E8;
	opacity: 1; /* Firefox */
  }
  
  .form-block .form-field .input-text::-ms-input-placeholder {
	color: #C3D5E8;
  }
  .contact-form h2,
  .login-container .form-block .form-field { margin-top: 32px; }
  .form-block .form-field.submit { margin-bottom:0; }
  .form-block .form-field.submit .btn{ border-radius: 4px; }
  
  .form-block .form-field .form-field-label{ margin-bottom: 4px; color:#000; }
  
  
  .form-block .form-field .has-generate-password .input-text{ padding-right: 130px; }
  .form-block .form-field .generate-password{
	  display: block;
	  position: absolute;
	  bottom: 5px;
	  top: 5px;
	  right: 6px;
	  width: 114px;
	  text-align: center;
	  border: 0;
	  border-radius: 4px;
	  background: #3D3ABB;
	  color: #fff;
	  font-size: 17px;
	  font-weight: bold;
	  /* font-family: 'DM Sans'; */
	  line-height: 1;
	  cursor:pointer;
  }
  
  #page-container.login-page{
	  display:flex;
	  min-height:100vh;
	  justify-content: center;
	  align-items: center;
	  padding: 30px;
  }
  
  #page-container.login-page .logo-form-wrapper{
	  width: 100%;
	  max-width:650px;
	  border:1px solid #6967EF;
	  padding:48px;
	  border-radius: 4px;
  }
  #page-container.login-page .logo-form-wrapper h3{ font-weight: 700; }
  .login-container{
	  width:100%;
	  max-width:650px;
  }
  .login-container .logo-block{ margin-bottom: 32px;}
  .login-container .logo-block img{ margin: 0 auto; }
  .login-container .form-block{ margin-top: 0; margin-bottom: 0; }
  .login-container .form-block > p{
	  margin-top:  24px;
	  text-align: center;
	  color:#E00909;
	  font-size: 16px;
	  font-weight: bold;
	  background-color: #FFF4F0;
	  border-radius: 6px;
	  padding: 12px;
  }
  .login-container .form-block > p:empty{ display: none ;}
  .login-container .forgot-password{ color:#3366FF; text-decoration:none; font-weight:bold; }
  .login-container .logo-form-wrapper{ margin-bottom: 32px; }
  .login-copyright{ font-size: 12px; margin-top: 32px; color:#63717E; font-weight: bold; }
  .login-copyright a{ color:#63717E; }
  .login-copyright .login-copyright-text{ color:#000; margin-top: 16px; font-size: 16px; font-weight: bold; }
  
  .form-block .form-field.dont-have-account{
	  color:#000;
	  font-weight: bold;
  }
  .form-block .form-field.dont-have-account a{ color:#3366FF; text-decoration: none; }
  
  .footer-payments-images img{ opacity: 0.5; }
  .footer-checkout-disclaimer{ font-size: 14px; }
  .footer-copyright,
  .footer-links,
  .footer-links a{color:#515151;  font-size: 16px; }
  .footer-links a{ text-decoration:none; }
  .footer-links a:hover{ text-decoration:underline; }
  .mobile-save-and-continue,
  .mobile-title{ display: none;}
  .checkout-section{
	  padding: 0px 30px 70px;
  }
  .checkout-section.thank-you{ padding-top: 70px; }
  .the-checkout-block{
	  background:#fff;
  }
  .the-checkout-block p{ margin-bottom: 5px; font-size: 12px; }
  
  .the-checkout-steps{
	  display:flex;
  }
  .the-checkout-steps .the-checkout-step{
	  min-width: 33.34%;
	  padding: 35px 10px;
	  border-bottom: 1px solid #ACD6FF;
	  transition: all 0.3s ease;
	  display: inline-flex;
	  justify-content: center;
	  align-items: center;
	  column-gap: 10px;
  }
  .the-checkout-steps .the-checkout-step.active{
	  width:100%;
	  border-bottom: 1px solid #3D3ABB;
	  position: relative;
  }
  .the-checkout-steps .the-checkout-step.active:before{	
	  content:"";
	  width:100%;
	  height:2px;
	  position:absolute;
	  bottom:-1px;
	  left:0;
	  background-color:rgba(61,58,187, 1);
	  display:block;
  }
  .the-checkout-steps .step-intro{
	  display: inline-flex;
	  width: 31px;
	  height: 31px;
	  border-radius: 100%;
	  border: 2px solid rgba(61,58,187, 0.3);
	  text-align: center;
	  align-items: center;
	  justify-content: center;
	  margin-right: 5px;
	  font-weight: bold;
	  color: rgba(61,58,187, 0.3);
	  flex: 0 0 31px;
  }
  .the-checkout-steps .the-checkout-step.active .step-intro{
	  background-color: rgba(61,58,187, 1);
	  border-color: rgba(61,58,187, 1);
	  color: #fff;
  }
  .the-checkout-steps .step-title{
	  font-size:24px;
	  color: rgba(61,58,187, 0.3);
	  font-weight:bold;
  }
  .the-checkout-steps .active .step-title{ color: rgba(61,58,187, 1); }
  .the-checkout-content-wrap{
	  padding: 85px 10px 50px 65px;
	  width: 56%;
  }
  .the-checkout-content-wrap h3{
	  color:#000;
	  margin-bottom:35px;
	  font-weight:bold;
  }
  .the-checkout-content-wrap h3.new-block{
	  margin-bottom:10px;
	  margin-top: 45px;
  }
  .the-checkout-content-wrap h3.first-block{
	  margin-bottom:10px;
	  margin-top: 0;
  }
  .form-block.flex-wrap{ column-gap: 25px; row-gap: 25px; }
  .form-block{ margin-bottom: 25px; }
  .form-block.flex-wrap .form-field.one-half{
	  flex: .5;
	  margin-bottom: 0;
  }
  .form-block.flex-wrap .form-field.one-third{
	  flex: .3;
	  margin-bottom: 0;
  }
  .form-block.flex-wrap .form-field.one-whole{
	  flex: 1;
	  margin-bottom: 0;
  }
  #modal-add-assets .form-block.flex-wrap .form-field.one-whole{ flex: auto; }
  .form-block.flex-wrap .form-field.two-third{
	  flex: .7;
	  margin-bottom: 0;
  }
  .the-checkout-block .form-block.flex-wrap .form-field{
	  margin-bottom: 0;
  }
  .the-checkout-block .form-block.flex-wrap .form-field label p{ padding-top:2px ;}

  .the-checkout-block .form-block.flex-wrap .form-field.required .input-text{
	border-color: #FF0000;
  }
  .the-checkout-block .form-block.flex-wrap .form-field.required{ position: relative; }
  .the-checkout-block .form-block.flex-wrap .form-field.required span.error-text.hide-element{ 
	display: block !important;
	color: #FF0000;
	position: relative;
	top:0;
	margin-top: 3px;
	font-size: 15px;
	}

	[data-step="1"] .form-block,
	[data-step="3"] .form-block{ margin-bottom: 16px; }
  
  .the-checkout-block .form-block.flex-wrap .form-field.required .input-text::placeholder {
	color: #FF0000;
	opacity: 1; /* Firefox */
  }
  
  .the-checkout-block .form-block.flex-wrap .form-field.required .input-text::-ms-input-placeholder {
	color: #FF0000;
  }
  
  .the-checkout-block .form-field .input-text{ border-color:rgba(178,178,178,0.5); border-radius:5px; }
  .the-checkout-block .btn.blue-btn svg{ margin-left: 0; }

  #checkout-step-2.submitting,
  #checkout-next-step.submitting{ background-color: rgba(61 58 187 / 69%); border-color: rgba(61 58 187 / 69%) !important; }
  #checkout-step-2.submitting svg,
  #checkout-next-step.submitting svg{ display: none; }
  #checkout-step-2 .loader-container,
  #checkout-next-step .loader-container{
	display: inline-flex;
	position: relative;
	width: 18px;
	height: 21px;
  }
  #checkout-step-2.submitting .loader-container .loader-spinner,
  #checkout-next-step.submitting .loader-container .loader-spinner{ display: block; background: transparent; }
  #checkout-step-2.submitting .loader-container .loader-spinner .loader,
  #checkout-next-step.submitting .loader-container .loader-spinner .loader{ background: transparent; }

  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
  
  .shield-spinner {
	animation: spin 1s linear infinite;
  }

  input:-webkit-autofill {
	animation-name: autofillStart;
	animation-duration: 0.01s;
	animation-iteration-count: 1;
  }
  
  @keyframes autofillStart {
	from {}
	to {}
  }

  .add-asset-container .form-field.checkbox-field,
  .the-checkout-block .form-field.checkbox-field{
	  position:relative;
	  padding-left: 38px;
  }
  .add-asset-container .form-field.checkbox-field{ margin-bottom: 0; }
  .add-asset-container .form-field.checkbox-field strong,
  .the-checkout-block .form-field.checkbox-field strong{
	  color: #3D3ABB;
  }
  .add-asset-container .form-field.checkbox-field input,
  .the-checkout-block .form-field.checkbox-field input{
	  position:absolute;
	  top:0;
	  left:0;
	  z-index:-1;
	  opacity: 0;
  }
  .add-asset-container .form-field.checkbox-field label span,
  .the-checkout-block .form-field.checkbox-field label span{
	  width: 25px;
	  height:25px;
	  position:absolute;
	  display:block;
	  left:0;
	  top:0;
	  border:1px solid rgba(178,178,178,0.5);
	  border-radius:5px;
	  z-index: 2;
  }
  .the-checkout-block .form-field.checkbox-field.required label span{
	border:1px solid #E00909;
  }
  .add-asset-container .form-field.checkbox-field input:checked + label span,
  .the-checkout-block .form-field.checkbox-field input:checked + label span{
	  border:1px solid #5B7FFF;
	  background: #5B7FFF url('./assets/images/checkbox-checked.png') no-repeat center;
  }
  .add-asset-container .form-field.checkbox-field label{ font-size: 14px; }
  .the-checkout-content{ display: flex; justify-content: space-between; }
  .checkout-order-summary h3{ color:#6967EF; margin-bottom: 40px; font-weight:bold; }
  .checkout-order-summary .steps-wrap{ display: none;}
  .the-checkout-order-summary{
	  width: 46%;
	  padding: 60px 45px 60px 40px;
	  flex: 0 0 577px;
  }
  .checkout-order-summary{
	  padding: 40px 35px;
	  border-radius: 20px;
	  background:#fff;
	  box-shadow: 0 4px 69px rgba(0,0,0,0.1);
  }
  
  .order-summary-item{ display: flex; column-gap: 14px; align-items: center; }
  .order-summary-item .order-summary-image{ flex: 0 0 64px; position:relative; }
  .order-summary-item .order-summary-details{ flex: 1; }
  .order-summary-details .order-title{
	  color: #000;
	  font-size: 16px;
	  font-weight: 400;
  }
  .order-summary-details .order-item-link,
  .order-summary-details .order-detail{ color:#707070; font-size: 12px; line-height:18px; }
  .order-summary-details .order-item-link a{color:#6967EF; font-size: 12px; text-decoration:none; font-weight:bold; }
  .order-item-price{ color:#000; font-size: 19px; font-weight:bold; }
  .order-summary-details .order-item-link{
	position: relative;
  }
  .order-summary-details .order-item-link ul{
    position: absolute;
    background: #fff;
    padding: 0;
    border-radius: 5px;
    list-style: none;
    box-shadow: 0px 0px 16px 0 rgba(0, 0, 0, 0.16);
    color: #000;
	top: 100%;
	margin-top: 5px;
	display: none;
  }
  .order-summary-details .order-item-link ul.active{ display: block; }
  .order-summary-details .order-item-link ul li{ 
	border-bottom: 1px solid rgba(178,178,178,0.5);
	position: relative;
	padding: 5px 20px 5px 10px;
	}
	.order-summary-details .order-item-link ul li:last-child{ border-bottom: 0; }
  .order-summary-details .order-item-link ul li:before{
	content: "";
    width: 8px;
    height: 8px;
    background: #B4E724;
    border-radius: 100%;
    position: absolute;
    right: 5px;
    top: 10px;
  }
  .order-summary-items{
	  padding-bottom: 25px;
	  border-bottom:1px solid #c6c6c6;
  }
  .order-summary-image .qty-bubble{
	  background:rgba(115,115,115,0.9);
	  border-radius:100%;
	  height:21px;
	  width:21px;
	  font-size:12px;
	  line-height:21px;
	  color:#fff;
	  display:block;
	  text-align:center;
	  font-weight:bold;
	  position:absolute;
	  top: -8px;
	  right:-8px;
  }
  .order-summary-price{ text-align: right; }
  .order-item-prev-price{
	  font-size: 11px;
	  color: #3D3ABB;
	  font-weight: 400;
  }
  .order-items-total{
	  display:flex;
	  justify-content: space-between;
	  align-items: center;
	  padding: 22px 0 25px;
  }
  .order-items-total .order-total-label{ font-weight:bold; font-size:20px; color:#000; }
  .order-items-total .order-total-prices{display: flex;column-gap: 9px;align-items: center;}
  .order-total-prices .order-item-currency{ color:#727272; font-size: 12px; }
  .order-total-prices .order-item-total-price{ font-weight:bold; font-size:24px; color:#000;}

  .the-checkout-block .recurly-element.recurly-element-focus{
	border-color: #000;
	outline: 1px solid #000;
  }
  /*
  .the-checkout-block .recurly-element.invalid{
	border-color:#FF0000
  }
	*/

	.the-checkout-block .form-block.flex-wrap .form-field.valid .input-text:focus,
	.the-checkout-block .recurly-block-cvv.valid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block .recurly-block-year.valid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block .recurly-block-month.valid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block .form-block.flex-wrap.valid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block.submitted .form-block.flex-wrap.valid .recurly-element.recurly-element-focus{
		outline: 1px solid #65b920
	}
	.the-checkout-block .form-block.flex-wrap .form-field.valid .input-text,
	.the-checkout-block .recurly-block-cvv.valid.focus .recurly-element,
	.the-checkout-block .recurly-block-year.valid.focus .recurly-element,
	.the-checkout-block .recurly-block-month.valid.focus .recurly-element,
	.the-checkout-block .form-block.flex-wrap.valid.focus .recurly-element,
	.the-checkout-block.submitted .form-block.flex-wrap.valid .recurly-element{
		position: relative;
		border-color:#65b920
	}
	
	.the-checkout-block .form-block.flex-wrap .form-field.valid .input-wrap,
	.the-checkout-block .form-block.flex-wrap .form-field.valid{ position: relative; }

	.the-checkout-block .recurly-block-cvv.valid.focus .recurly-element:before,
	.the-checkout-block .recurly-block-year.valid.focus .recurly-element:before,
	.the-checkout-block .recurly-block-month.valid.focus .recurly-element:before,
	.the-checkout-block .form-block.flex-wrap.valid.focus .recurly-element:before,
	.the-checkout-block.submitted .form-block.flex-wrap.valid .recurly-element:before,
	.the-checkout-block .form-block.flex-wrap .form-field.valid .input-wrap:before{
		content: "";
		display: block;
		width: 50px;
		background: #fff;
		position: absolute;
		bottom: 1px;
		top:1px;
		right: 1px;
		background: #fff url('./assets/images/green-check.png');
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 0 5px 5px 0;
		z-index: 3;
	}
	
	.the-checkout-block.submitted .form-block.flex-wrap .form-field.required .input-text:focus,
	.the-checkout-block.submitted .recurly-block-cvv.invalid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block.submitted .recurly-block-year.invalid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block.submitted .recurly-block-month.invalid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block.submitted .form-block.flex-wrap.invalid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block .form-block.flex-wrap .form-field.required .input-text:focus,
	.the-checkout-block .recurly-block-cvv.invalid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block .recurly-block-year.invalid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block .recurly-block-month.invalid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block .form-block.flex-wrap.invalid.focus .recurly-element.recurly-element-focus,
	.the-checkout-block.submitted .form-block.flex-wrap.invalid .recurly-element.recurly-element-focus{
		outline: 1px solid #FF0000;
	}
	.the-checkout-block.submitted .recurly-block-cvv.invalid .recurly-element,
	.the-checkout-block.submitted .recurly-block-year.invalid .recurly-element,
	.the-checkout-block.submitted .recurly-block-month.invalid .recurly-element,
	.the-checkout-block.submitted .form-block.flex-wrap.invalid .recurly-element,
	.the-checkout-block .recurly-block-cvv.invalid.focus .recurly-element,
	.the-checkout-block .recurly-block-year.invalid.focus .recurly-element,
	.the-checkout-block .recurly-block-month.invalid.focus .recurly-element,
	.the-checkout-block .form-block.flex-wrap.invalid.focus .recurly-element,
	.the-checkout-block.submitted .form-block.flex-wrap.invalid .recurly-element{
		border-color:#FF0000
	}
  .the-checkout-block .recurly-element, 
  .the-checkout-block .recurly-hosted-field{
	height: 55px;
    border-color: rgba(178, 178, 178, 0.5);
    border-radius: 5px;
    padding: 0 24px 1px;
  }
  	@media only screen and (max-width: 749px) {
		[data-step="2"] .form-block,
		[data-step="1"] .form-block{ margin-bottom: 12px !important; }
		.the-checkout-block .form-block.flex-wrap .form-field.required span.error-text.hide-element{ position: relative; top:0; font-size: 14px; }
	}
  
  .payment-accord-header-image{
	font-size: 12px; column-gap: 5px; color:#707070;
	display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
}
  .payment-accordion-header{ border:1px solid #B2B2B2; padding: 15px; align-items: center; }
  .payment-accord-title{ font-size: 20px; font-weight: bold; color:#000; align-items: center; display:flex; }
  .payment-accord-title span{
	  width: 27px;
	  height:27px;
	  margin-right:19px;
	  display:block;
	  border-radius: 100%;
	  border: 1px solid #ddd;
  }
  .payment-accord-content{
	  border-left:1px solid #B2B2B2;
	  border-right:1px solid #B2B2B2;
	  border-bottom:1px solid #B2B2B2;
	  padding:25px 38px;
  }
  .payment-accord-content .form-block .form-field .form-field-label{ font-weight:bold; }

  [data-step="2"] .payment-accord-content .form-block .form-field .form-field-label,
  .payment-accord-content .form-block .form-field.zipcode-form .form-field-label{ font-weight: 400; }

  .the-checkout-content [data-step="3"] .desc{display: none; }

  .payment-accord-content .recurly-element-cvv,
  .payment-accord-content .recurly-element-month,
  .payment-accord-content .recurly-element-year,
  .payment-accord-content .recurly-hosted-field-cvv,
  .payment-accord-content .recurly-hosted-field-month,
  .payment-accord-content .recurly-hosted-field-year,
  .payment-accord-content .recurly-element-number,
  .payment-accord-content .recurly-hosted-field-number{ margin: 0; width: 100%; }

  .payment-accord-content .recurly-date-wrap{ display: flex; gap: 20px;  }
  .payment-accord-content .recurly-date-wrap > div{ width: 50%;}

  .the-checkout-block p.payment-info-p{ margin-bottom: 7px; }
  .payment-accordion .payment-accord-content{ display: none; }
  .payment-accordion.active .payment-accord-content{ display:block; }
  .payment-accordion.active .payment-accord-title span{ 
	  background:url('./assets/images/radio-button-image.png') no-repeat center;
  }
  .payment-accordion.active .payment-accordion-header{ background:#dedee6;  }
  .payment-accordion{ margin-bottom:15px; cursor: pointer; }
  .the-checkout-content .secure-images{
	  padding: 21px 10px 0;
  }
  .the-checkout-content .secure-images .flex-wrap{ column-gap: 14px;}
  .payment-alerts-block{
	  border-radius:20px;
	  padding:20px;
	  background:#F9F9FF;
	  margin-bottom:25px;
	  color: rgb(16 16 16 / 100%);
	  font-size: 13px;
	  text-align:center;
  }
  .the-checkout-content .secure-images.hide,
  .payment-alerts-block.hide{ display: none;}
  .payment-alerts-block .alert-text{
	  display: inline-flex;
	  color:#fff; 
	  background:#FB3838;
	  border-radius:10px;
	  font-weight:bold;
	  padding: 6px 11px;
	  align-items: center;
	  column-gap:4px;
	  margin-right:5px;
  }
  .payment-alerts-block .alert-text span{
	  font-size:19px;
	  width: 28px;
	  header:28px;
	  border:1px solid #fff;
	  border-radius:100%;
  }
  .payment-alerts-block .payment-alerts-text{ margin-bottom: 20px; font-size: 14px; }
  .payment-alerts-block .blur-images{
	  display: flex;
	  justify-content: center;
	  column-gap: 50px;
	  background:url('./assets/images/blur-image-bg.png') no-repeat center;
	  margin-bottom: 22px;
	  width: 100%;
	  flex-wrap: wrap;
	  row-gap: 15px;
  }
  .payment-alerts-block > .flex-wrap.flex-wrap-block{
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
	margin-bottom: 15px;
    flex-wrap: nowrap;
  }
  .payment-alerts-block .payment-alert{
	border:1px solid #E9E8FD;
	background: #fff;
	border-radius: 9px;
	padding: 11px;
	font-size: 14px;
	color:#000;
    min-width: 33%;
  }
  .payment-alerts-block .payment-alert svg path{ fill: #000; }
  .payment-alerts-block .payment-alert .icon{
	width: 39px;
	height: 39px;
	display: flex;
	margin: 0 auto 5px;
	background: rgba(105,103,239,0.15);
	border-radius: 9px;
	align-items: center;
	justify-content: center;
  }
  .payment-alerts-block .payment-alert.email .icon svg{
	width: 24px;
	height: 30px;
  }
  .payment-alerts-block .payment-alert.phone .icon svg{
	width: 18px;
	height: 30px;
  }
  .payment-alerts-block .payment-alert.password .icon svg{
	width: 21px;
	height: 30px;
  }
  .payment-alerts-block .blur-images img{ width: 212px; max-width: 100%;}
  .the-checkout-block p.info{ font-size: 14px; margin-bottom:0; }
  .payment-alerts-block .d-alert-level{ text-transform: none; }
  .payment-alerts-block .d-alert-level.low-risk{
	color:#0A0E19;
	background-color: rgba(105,103,239,0.15);
  }
  /*
  .the-checkout-block .payment-alerts-block p{	
	  color:#000;
	  font-size:18px;
  }
  */
  .the-checkout-block .payment-alerts-block p:last-of-type{ margin-bottom:0; font-weight: bold; color:#2454FF; }
  
  .payment-automatic-renewal-block{
	  background:#F2F2FF;
	  padding: 11px 24px 24px;
	  border-radius:20px;
	  margin: 20px 0;
  }
  .payment-automatic-renewal-block ul{
	  margin-left: 20px;
	  color:#101010;
	  font-size:19px;
	  margin-top:14px;
  }
  .payment-automatic-renewal-block ul li{ margin-bottom: 20px; }
  .payment-automatic-renewal-block ul li:last-child{ margin-bottom:0; }
  .show-on-autorenew{ display:none; }
  .the-checkout-block .btn-back{ margin-bottom:20px; display:none; }
  
  .the-checkout-steps.step-1.step-3 .the-checkout-step[data-step="3"],
  .the-checkout-steps.step-1 .the-checkout-step[data-step="2"],
  .the-checkout-steps.step-3 .the-checkout-step,
  .the-checkout-steps.step-2 .the-checkout-step[data-step="1"]{
	  cursor:pointer;
  }
  
  .thank-you-block{
	  background:#fff;
	  width:100%;
	  padding: 60px 30px;
	  margin: 0 auto;
	  max-width: 823px;
  }
  .thank-you-confirmation-code{
	  font-size:24px;
	  line-height:30px;
	  color:#6967EF;
	  margin-top: 8px;
  }
  .thank-you-icon-status{ margin: 15px 0; }
  .thank-you-icon-status svg{ display: block; margin: 0 auto; }
  .thank-you-customer-info,
  .thank-you-order-content{ width: 100%; max-width: 600px; margin: 0 auto 13px; color:rgba(0, 0, 0, 0.5); font-size: 18px; }
  .thank-you-order-content p strong{ font-size: 17px; }
  
  .thank-you-customer-info{
	  padding: 30px 40px;
	  background:#fff;
	  box-shadow: 0 4px 69px rgba(0,0,0,0.1);
	  border-radius:20px;
  }
  .thank-you-customer-info h3{ margin-bottom: 10px; }
  .customer-info-title{
	  color:#6967EF; font-weight: bold; text-align:center; padding: 0 0 10px; border-bottom:1px solid #C6C6C6; margin-bottom:10px;
	  font-size:20px;
	  line-height:24px;
  }
  .c-info-title{
	  font-weight:bold;
	  color:#6967EF;
	  margin-bottom:0px;
  }
  .customer-info-block{ margin-bottom: 14px; }
  .customer-payment-method img{ display: inline-flex; vertical-align:middle; position:relative;top:-2px; }
  .thank-you-customer-info-save{
	  padding: 20px 30px;
	  background:#fff;
	  box-shadow: 0 4px 69px rgba(0,0,0,0.1);
	  border-radius:20px;
	  font-size: 14px;
	  margin: 25px auto 30px;
	  width: 100%; max-width: 544px;
	  color:#707070;
  }
  .thank-you-customer-info-save input{ margin-right: 5px; position:relative; top: 2px; }
  .thank-you-custom-back-btn{
	  margin: 40px auto 0;
	  width: 100%; max-width: 544px;
	  text-align: center;
  }
  .thank-you-custom-back-btn .btn{
	  font-size: 20px; width:100%;	
	  display: flex;
	  align-items: center;
	  justify-content: center;
  }
  .thank-you-custom-back-btn  a{ color:#3D3ABB; font-weight:bold; }
  
  .show-hide-password{
	  display:block;
	  cursor:pointer;
	  background: url('./assets/images/show-hide-password.png') top center no-repeat;position: absolute;
	  bottom: 17px;
	  right: 10px;
	  height:30px;
	  width: 30px;
	  background-size: 30px;
  }
  .show-hide-password.active{
	  background-position:bottom center;
  }
  .show-password{ position:relative; }
  
  #footer-signup{
	  padding: 10px 30px 30px;
	  color:#63717E;
	  font-size:12px;
	  font-weight:bold;
  }
  #footer-signup p:first-of-type{ margin-bottom:15px; }
  #footer-signup p:first-of-type a{color:#63717E;}
  #footer-signup p:last-of-type{
	  color:#000;
	  font-size:16px;
  }
  .phone-on-header a{color: #8D9BA9; text-decoration: none;}
  .phone-on-header.btn-flex-holder { column-gap: 5px; }
  
  .steps-block.hidden-blocks{
	  display: none;
  }
  
  .blur-image-1 svg{
	  width: 25px;
	  height: 33px;
	  display: block;
  }
  
  .blur-image-2 svg{
	  width: 17px;
	  height: 33px;
	  display: block;
  }
  .blur-image.hide{ display: none; }
  .blur-image .flex-wrap{position: relative;}
  .blur-image .flex-wrap{
	  font-weight: bold;
	  font-size: 14px;
	  column-gap: 5px;
	  justify-content: center;
	  background: #fff;
	  border-radius: 10px;
	  padding: 5px;
	  min-width: 196px;
  }
  .blur-image .flex-wrap:before{
	  content: "";
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  border-radius: 10px;
	  background: rgba(255,255,255,0.1);
	  -webkit-backdrop-filter: blur(5px);
	  backdrop-filter: blur(3px);
  }
  .form-notification.successful{
	  margin-bottom: 20px;
	  border: 2px solid #0f9961;
	  color: #0f9961;
	  padding: 10px;
	  text-align: center;
	  background: #fff;
	  font-weight: 400;
  }
  .modal-bg{
	  position: fixed;
	  top: 0;
	  left: 0;
	  background: rgba(0, 0, 0, 0.4);
	  z-index: 1001;
  }
  .fadeOut{
	  opacity:0;
	  width:0;
	  height:0;
	  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
  
  }
  .fadeIn{
	  opacity:1;
	  width:100%;
	  height:100%;
	  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
  
  }
  
  .modal-content{
	  width: 754px;
	  max-width: calc(100% - 30px);
	  position: fixed;
	  background: #fff;
	  border-radius: 15px;
	  padding-top: 150px;
	  top: 0;
	  left: 50%;
	  transition-timing-function: cubic-bezier(0, 0, 1, 1);
	  transition-timing-function: ease-out;
	  transition: 0.25s;
	  transform: translate(-50%, 200vh);
	  padding: 45px 95px;
	  height: auto;
  }
  #modal-errors.modal-content{
	padding: 45px 30px 20px;
  }
  .fadeIn .modal-content{
	  transition-delay: 500ms;
	  transform: translate(-50%, 102px);
  }
  .modal-content h3{
	  font-size: 28px;
	  text-align: center;
	  margin-bottom: 30px;
  }
  .modal-close{
	  position: absolute;
	  top: -22px;
	  right: -22px;
	  background: #fff;
	  border-radius: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  padding: 12px;
	  box-shadow: 0 0 15px rgba(0,0,0,0.15);
	  z-index: 3;
  }
  
  .add-assets-actions-list{
	  display: flex;
	  margin: 0 0 45px;
	  padding: 0;
	  list-style: none;
	  column-gap: 10px;
	  flex-wrap: wrap;
  }
  .add-assets-actions-list li{
	  width: calc(25% - 10px);
	  flex-grow: 1;
  }
  .add-assets-actions-list li a{
	  text-decoration: none;
	  display: flex;
	  width: 100%;
	  height: 100%;
	  justify-content: center;
	  background:#F7F9FC;
	  border-radius: 8px;
	  text-decoration: none;
	  font-size: 14px;
	  font-weight: 700;
	  color:rgba(0, 0, 0, 0.5);
	  padding: 32px 0;
	  border:2px solid transparent;
	  flex-direction: column;
	  align-items: center;
  }
  .add-assets-actions-list li.active a{
	  color:#3D3ABB;
	  border:2px solid #3D3ABB;
  }
  .add-assets-actions-list li .icon-block{
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  height: 38px;
	  margin-bottom: 16px;
  }
  
  .add-assets-actions-list li .icon-block svg{
	  fill-opacity: 0.25;
  }
  .add-assets-actions-list li .icon-block svg path{
	  fill: #000;
  }
  .add-assets-actions-list li.active .icon-block svg{
	  fill-opacity: 1;
  }
  .add-assets-actions-list li.active .icon-block svg path{
	  fill: #3D3ABB;
  }
  .add-assets-actions-list li.assets-person .icon-block svg,
  .add-assets-actions-list li.assets-emails .icon-block svg,
  .add-assets-actions-list li.assets-passwords .icon-block svg{
	  width: 37px;
	  height: 30px;
  }
  .add-asset-container h4{ margin-bottom: 20px;}
  .add-asset-container .form-block .form-field .input-text{
	  font-size: 17px;
	  border-color: #D9D9D9;
	  border-radius: 4px;
  }
  .add-asset-container .btn.blue-btn.btn-next{
	  width: 100%;
	  font-size: 17px;
  }
  .add-asset-container .form-block .form-field.submit-field{ margin-bottom: 0; width: 100%; }
  .add-asset-container .asset-placeholder-response:empty{ margin-bottom: 0; }
  .add-asset-container .asset-placeholder-response{ margin-bottom: 20px; }
  .add-asset-container.hide{ display: none; }
  
  .button-link a{
	  display: inline-flex;
	  padding: 8px 20px;
	  font-size: 14px;
	  text-decoration: none;
	  color:#3D3ABB;
	  border:1px solid #3D3ABB;
	  border-radius: 100px;
	  font-weight: bold;
  }
  
  
  /*
  * landing page v2
  */
  .landing-page-v2{
	  color:#6E7C8B;
  }
  .landing-page-v2 #header{
	  padding-top:25px;
	  padding-bottom:25px;
	  border-bottom:0;
	  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
	  position: relative;
	  z-index: 3;
  }
  .landing-page-v2 .site-slogan{
	  color:#222222;
	  font-weight:bold;
  }
  .landing-page-v2 .hero-banner .hero-content{
	  max-width: 580px;
  }
  .landing-page-v2 .hero-banner h1{ margin-bottom:30px; }
  .landing-page-v2 .hero-banner p.big{ font-size: 24px; max-width:615px; margin-bottom:30px; }
  .landing-page-v2 .hero-banner ul{
	  margin: 0 0 30px 38px;
	  padding:0;
	  list-style:none;
  }
  .landing-page-v2 .hero-banner ul li{
	  padding-left: 36px;
	  background: url('./assets/images/checklist-circle.png') no-repeat top left;
	  margin-bottom:24px;
	  color:#222;
	  font-weight:bold;
	  font-size:20px;
	  background-position: 0 2px;
  }
  .landing-page-v2 .image-with-text-block ul{ list-style: none; }
  .landing-page-v2 .image-with-text-block ul li{
	  padding-left: 36px;
	  background: url('./assets/images/checklist-circle.png') no-repeat top left;
	  margin-bottom:14px;
	  color:#000;
	  background-position: 0 2px;
  }
  .landing-page-v2 .logos{ margin-top:14px; }
  .landing-page-v2 .image-with-text-block .page-content-block{ max-width: 646px; margin: 0 auto; }
  .landing-page-v2 .content-first .image-with-text-block .page-content-block{ margin: 0; padding: 0; }

  .page-content-block ol{ margin: 0 0 0 30px; }
  
  .landing-page-v2 .text-intro{
	color:#FB3838;
	font-size: 24px;
	font-weight: 500;
  }
  .landing-page-v2 .intro.button-type span{
	font-size: 18px;
	text-transform: uppercase;
	color:#000;
	background: rgba(61,58,187,0.1);
	padding: 16px;
	border-radius: 5px;
	display: inline-flex;
  }
  .landing-page-v2 .image-with-text-block{ padding: 50px 0;}
  .landing-page-v2 .hero-banner .btn-flex-holder{ column-gap: 30px; }
  .landing-page-v2 .radial-bg.loaded{
	  background-image: url('./assets/images/radial-v2-1.jpg');
  }
  .landing-page-v2 .page-content-block{ row-gap: 30px; }
  .landing-page-v2 .logos{ column-gap:48px; }

  .light-bg{ background: #FAFAFF; }

  .landing-page-v2 .image-with-text-block .btn.text-blue{
	color:#3D3ABB;
  }
  .landing-page-v2 .image-with-text.content-first .image-with-text-block{ 
    justify-content: space-between;
  }
  
  .ip-block{
	  width: 100%;
	  max-width: 822px;
	  padding: 48px 28px;
	  background:#fff;
	  margin: 0 auto;
	  font-size: 26px;
	  border-radius: 37px;
	  background: url('./assets/images/landing-page-ip-block-bg.png') no-repeat center;
	  background-size: 100% auto;
	  padding: 1px;
	  box-sizing: border-box;
	  min-height: 232px;
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  color:#fff;
	  line-height: 30px;
  }
  .ip-block > div{ margin-bottom: 20px; }
  .ip-block > div span{ font-weight: 500;}
  .ip-block > div:last-of-type{ margin-bottom:0; }

  #v2-custom-section-1{
	background: url('./assets/images/landing-page-v2-ip-bg.jpg') no-repeat center;
	background-size: cover;
  }
  
  #v2-custom-section-1.flexbox .wrapper{
	  padding-top: 120px;
	  padding-bottom:120px;
  }
  #v2-custom-section-1 p.desc,
  #v2-custom-section-1 h2,
  #v2-custom-section-1 .intro{ color:#fff; }
  #v2-custom-section-1 p.desc{
	font-size: 21px;
	line-height: 28px;
	margin-left: auto;
	margin-right: auto;
	max-width: 868px;
  }
  #section-2c,
  #v2-custom-section-2{overflow:visible;}
  #v2-custom-section-2.flexbox .wrapper{
	  padding-top: 0px;
	  padding-bottom:0px;
	  position:relative;
	  z-index:3;
  }
  .landing-page-v2 .custom-page-content-block-1 {
	  border-left: 7px solid #6967ef; 
	  background:#fff;
	  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  }
  .landing-page-v2 .custom-page-content-block-1 .page-content-block{
	  padding: 45px 57px;
  }
  #v2-custom-section-2 .the-bar{
	  max-width:1256px;
	  border-top:1px solid #DADADA;
	  padding: 16px;
	  font-size:14px;
	  text-align:center;
  }
  .landing-page-v2 .grid-icon-boxes .grid-icon-box{
	  background-color:#fff;
  }
  .landing-page-v2 .custom-page-content-block-1,
  #section-2c > .wrapper{
	  margin-top: -250px;
  }
  .landing-page-v2 #section-3 > .wrapper{
	  padding-bottom:315px;
	  padding-top: 115px;
  }
  .landing-page-v2 .number-grid {
	  margin-top:30px;
	  align-items: flex-end;
  }
  .landing-page-v2 .number-grid .grid-item{
	  border-left:2px solid #6967EF;
	  padding-left: 20px;
  }
  
  .landing-page-v2 .custom-block-style-2 .styled-content{
	  max-width: 1166px;
  }
  .landing-page-v2 .custom-block-style-2 .styled-content h3{
	  margin-bottom: 58px;
	  max-width:600px;
  }
  .landing-page-v2 .custom-block-style-2 .styled-content .number-block{
	  margin-bottom:5px;
	  font-size: 40px;
	  line-height: 1em;
	  font-weight:500;
  }
  .landing-page-v2 .custom-block-style-1 .styled-content h3{ margin-bottom:20px; }
  .landing-page-v2 .custom-block-style-1{
	  background: url('./assets/images/section-5-bg.jpg') no-repeat center;
	  background-size: cover;
  }
  .custom-slick-slider-6 .slick-dots{
	  margin:40px 0 0;
	  padding:0; list-style:none; display: flex; flex-wrap:wrap; column-gap: 30px; row-gap:30px;
	  justify-content: center; }
  .custom-slick-slider-6 .slick-dots button{
	  width: 136px;
	  overflow:hidden;
	  text-indent:-999px;
	  height:3px;
	  background:#D7D6E9;
	  border-radius: 0;
	  border:0;
	  cursor:pointer;
  }
  .custom-slick-slider-6 .slick-dots .slick-active button{ background:#3D3ABB; }
  .custom-slick-slider-6 .slick-slide img{ margin: 0 auto; }
  .custom-slick-slider-6 .slick-slide > div:first-child{ margin-bottom: 30px; min-height:256px; }
  
  .landing-page-v2 .pricing-single-block{
	  border: 1px solid #DFDFDF;
	  padding: 40px;
	  background:#fff;
	  row-gap: 20px;
	  flex-direction: column;
  }
  .landing-page-v2 .price-table-section .price-table-title{ font-size: 24px;  }
  .landing-page-v2 .price-table-list ul li:first-child:after,
  .landing-page-v2 .price-table-list ul li:last-child:after{margin-top:0; }
  .landing-page-v2 .price-table-section .logos{ opacity: 0.2; column-gap:20px; }
  .landing-page-v2 .price-table-btn.first{ margin-top: 30px; }
  #contactForm { margin-top: 30px; }
  #contactForm .form-field{ margin-bottom: 15px;}

  .landing-page-v2 .price-table.flexbox .wrapper{ padding-top: 100px;}


.blocked-page{
	width: 100vw;
	height: 100vh;
	display: flex;
    flex-direction: column;
    align-items: center;
	row-gap: 20px;
    padding-top: 5vh;
}

#show-risk-alerts-section{
	padding: 0 45px 45px 65px;
	background: #fff;
}
.badge{
	display: inline-flex;
	column-gap: 4px;
	padding: 4px 6px;
	border-radius: 6px;
	font-size: 14px;
	font-weight: 700;
    align-items: center;
}
.badge .badge-icon{
	width: 17px;
	height: 17px;
	display: flex;
	border-radius: 100%;
	border: 1px solid #fff;
    align-items: center;
    justify-content: center;
	font-size: 12px;
}
.badge.alert{
	color:#fff;
	background: #FB3838;
}
#show-risk-alerts-section .wrap{
	padding: 40px 35px;
	background: #F9F9FF;
	border-radius: 11px;
}
#show-risk-alerts-section .user-assets-protection-single{ background-color: #fff; }
.d-block-custom .d-header-custom{
	display: flex;
	column-gap: 22px;
	row-gap: 22px;
	flex-wrap: wrap;
    align-items: center;
	margin-bottom: 25px;
}
.d-block-custom .d-header-custom h3{ 
    font-size: 28px;
	font-weight: 500;
}
.d-block-custom .d-desc-custom{
	font-size: 15px;
	color:#000;
	display: flex;
    justify-content: space-between;
	flex-wrap: wrap;
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 25px;
}
.d-block-custom .d-desc-custom svg{ position: relative; top: 3px; margin-right: 5px; }


.steps-wrap .desc{
	color:#000;
	margin-top: 20px;
}
.steps-wrap .desc a{ color:#000; }

.testimonials-with-ratings .the-flex-wrap .one-third{
	background-color: #fff;
	padding: 35px 25px;
	border:1px solid rgba(0, 0, 0, 0.2);
	border-radius:20px;

}
.testimonials-with-ratings .the-flex-wrap .one-third h3{ margin-bottom: 20px; }
.testimonials-with-ratings .the-flex-wrap  .rating-and-verified{
	margin-bottom:  20px; color:#000; font-size: 14px;
	row-gap: 15px;
	column-gap: 15px;
}
.testimonials-with-ratings .the-flex-wrap .verified{
	display: flex;
	align-items: center;
	column-gap: 4px;
}
.testimonials-with-ratings .the-flex-wrap .verified .icon{
	width: 17px;
    height: 17px;
	width: 17px;
	display: inline-flex;
    align-items: center;
	background: url('./assets/images/checklist-circle.png') no-repeat top left;
    background-size: contain;
}
.testimonials-with-ratings .the-flex-wrap  .content-block p{ margin-bottom: 30px; }
.testimonials-with-ratings .the-flex-wrap  .content-block p.author{ font-weight: bold; color:#000; margin-bottom: 0; }
.testimonials-with-ratings .text-blue{
	color:#3D3ABB;
}


/*
* landing page v2 r2
*/
.landing-page-v2.v2-r2 .custom-intro{
	font-size: 36px;
	line-height: 1.3em;
	font-weight: 700;
	color:#000;
}
.landing-page-v2.v2-r2 .custom-intro span{
	display: inline-flex;
	margin-right: 10px;
    top: -10px;
    position: relative;
    vertical-align: middle;
}
.landing-page-v2.v2-r2 .hero-banner .hero-content{ max-width: 750px; }

.landing-page-v2.v2-r2 .protected-by h5{
	color: #8D9BA9;
	margin-bottom: 30px;
}
.landing-page-v2.v2-r2 .logos{ column-gap: 20px; }

.landing-page-v2.v2-r2 #v2-custom-section-1 .medium-page-content-block{ max-width: 1160px;}
.landing-page-v2.v2-r2 #v2-custom-section-1 h2{ font-weight: 600; }

.warning-section{ margin-top: 120px; }
.landing-page-v2.v2-r2 .warning-section h3{ margin-bottom: 10px; }
.landing-page-v2.v2-r2 .warning-section h2{ font-weight: 600; margin-bottom: 50px; }
.landing-page-v2.v2-r2 .warning-section .audio-grid{ margin-bottom: 50px; }
.landing-page-v2.v2-r2 .warning-section .audio-grid img{ max-width: 100%; }

.image-with-text-full-width{
	padding: 80px 0;
}
.image-with-text-full-width .flex-wrap-setup{
	display: flex;
    align-items: center;
}
.image-with-text-full-width .flex-wrap-setup .content,
.image-with-text-full-width .flex-wrap-setup .image{
	width: 50%;
}

.image-with-text-full-width.content-first .flex-wrap-setup {
    flex-direction: row-reverse;
}
.image-with-text-full-width.content-first .flex-wrap-setup .content { padding-left: 30px; }
.image-with-text-full-width.content-first .flex-wrap-setup .content .wrap{
	padding-right:40px;
	padding-left: 0;
}
.image-with-text-full-width.content-first .flex-wrap-setup .wrap{
	display: flex;
	row-gap: 25px;
    flex-direction: column;
    max-width: calc(1615px / 2);
	margin-left:  auto;
}
.image-with-text-full-width.content-first .flex-wrap-setup .image img{
	border-radius: 30px 0 0 30px;
	max-width: 100%;
	margin-left: auto;
}

.prevent-box .one-third{
	border-radius: 30px;
	border:1px solid #6967EF;
	padding: 40px;
}
.prevent-box .one-third {
	font-size: 20px;
	color:#222B34;
	font-weight: bold;
}
.prevent-box .icon-grid{ column-gap: 16px;  justify-content: space-between;}
.prevent-box .col-gap-30 .one-third{ width: calc(33.33% - 14px); }
.content-grid-block p,
.prevent-box .small-wrap{
	font-size: 18px;
	color:#222B34;
	font-weight: bold;
}
.landing-page-v2.v2-r2  h3{ font-weight: 600;}
.landing-page-v2.v2-r2 .content-grid-block .content-right.flex-wrap{
    align-items: center;
}
.landing-page-v2.v2-r2 .offset-content-1 .btn-flex-holder{ margin-top:50px; }
.landing-page-v2.v2-r2 .grid-icon-boxes{ display: flex; flex-wrap: wrap ;  justify-content: space-between;}
.landing-page-v2.v2-r2 .grid-icon-boxes .grid-icon-box{
	width: calc(50% - 16px);
	position: relative;
	padding-bottom: 100px;
}
.landing-page-v2.v2-r2 .grid-icon-boxes .grid-icon-box .learn-more{
	position: absolute;
	bottom: 40px;
	left: 40px;
}
.grid-icon-boxes .grid-icon-box .learn-more a{ font-weight: 600;}

.landing-page-v2.v2-r2 .price-table-list ul li .accord-item{
	background:url('./assets/images/checklist-circle.png') no-repeat center;
    background-position: 0 25px;
    padding-left: 28px;
}

.landing-page-v2.v2-r2 .price-table-section .price-table-btn{ align-items: center; margin-bottom: 30px; }
.landing-page-v2.v2-r2 .price-table-section .price-table-btn .btn{ width: auto; }
.landing-page-v2.v2-r2 .price-table-section .price-table-block{ row-gap: 15px; }
.landing-page-v2.v2-r2 .small-wrap h2 + h3{ font-weight: 400; }

.view-all-pricing{ text-align: center; margin-bottom: 70px; }
.view-all-pricing a{
	color:#2454FF;
    font-weight: 600;
    text-decoration: none;
}

.landing-page-v2.v2-r2 .slick-track{ display: flex;  }
.landing-page-v2.v2-r2 .slider-block .slick-list{ overflow: hidden !important; }
.landing-page-v2.v2-r2 .custom-slide-wrapper{
	max-width: calc(100% - 30px); margin-right: 0; padding: 50px 40px 100px;
	min-height: 450px;
	height: auto;
	position: relative;
}
.landing-page-v2.v2-r2 .slider-testimonial{
    flex-direction: column;
    column-gap: 73px;color:#fff; 
}
.landing-page-v2.v2-r2 .slider-testimonial .content h4{ color:#fff; margin-bottom:15px; }
.landing-page-v2.v2-r2 .slider-testimonial .content .author{ margin-top: 15px;
	display: flex;
	column-gap: 10px;
    align-items: center;
    position: absolute;
    bottom: 40px;
    left: 40px;
}
.landing-page-v2.v2-r2 .slider-testimonial .content .author .author-image img{ border-radius: 62px;}


.custom-banner-layout-1{
	background:url('./assets/images/custom-banner-image.jpg') no-repeat center;
    background-position:right center;
    background-size: cover;
}
.custom-banner-layout-1 .wrapper{
    display: flex;
    align-items: center;
    align-content: center;
    min-height: 653px;
	padding: 40px 0;
}
.custom-banner-layout-1 .mobile-only{ display: none; }
.custom-banner-layout-1 .btn-flex-holder{ margin-top: 40px; }

.main-nav li{ position: relative;}
.main-nav li .dropdown{
	position: absolute;
	z-index: 5;
	width: 220px;
	padding: 0;
	background: #fff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	display: none;
    top: 37px;
    padding: 10px;
    border-radius: 8px;
}
.main-nav li:hover > .dropdown{ display: block; }
.main-nav > ul > li.has-nav:hover:before{
	content: "";
	display: block;
	position: absolute;
	left: -10px;
	right: -10px;
	top: 10px;
	height: 190px;
	z-index: 2;
}
#nav-menu .dropdown ul a{ font-size: 14px; }
#nav-menu .dropdown ul li a{ display: block; padding: 10px; color:#000;font-weight: 400; }

.copy-links ul li{ display: inline-flex; border-right: 1px solid #000; padding: 0 10px;}
.copy-links ul li:last-child{ border-right: 0; }
.copy-links ul{
	list-style: none; margin: 10px 0 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 0;
    row-gap: 15px;
}
.copy-links-inline a,
.copy-top a,
.copy-links ul li a{
    color: #000000;
    font-size: 14px;
	text-decoration: none;
	line-height: 14px;
}
.copy-links-inline{
    display: inline-flex;
    align-items: center;
    column-gap: 5px;
    margin-left: 10px;
}

.price-table-block .price-table-content{ min-height: 56px; }

.complete-profile-submit{ margin-top: 70px; }

.dashboard-forms .response{
	display: none;
    background-color: #3d3abb;
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 30px;
    border: 1px solid;
    padding: 30px;
    text-align: center;
    color: #fff;
}
.dashboard-forms .response.show{
	display: block;
}
.cancel-plan-text{ color:#FB3838; }
#cancel-plan-button{ margin-top: 30px; background-color: #FB3838; border-color: #FB3838; }

.order-items-next{ margin-top: 0px; }
.payment-method-type{ font-size: 18px; color:#000; display: inline-flex; margin-right: 10px; cursor: pointer; }
.payment-method-type span{
	width: 27px;
	height: 27px;
	display: inline-flex;
	margin-right: 5px;
	background: #fff;
	border-radius: 100%;
	border:1px solid #dfdfdf;
}
.payment-method-type.active span{
	background:url('./assets/images/radio-button-image.png') no-repeat center #fff;
}
.order-items-content h4.payment-method-type-title{ margin-bottom: 20px !important; }

.payment-method-container .recurly-element{ height: 4em; }

.order-items-next[data-paymentmethod="recurly"]{ display: none;}
.order-items-next.active[data-paymentmethod="recurly"]{ display: block;}

.asset-placeholder-response .error{ color:#E00909 }
.asset-placeholder-response .success{ color:#0f9961; }


.admin-header{
	display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.admin-header .page-title-block{ width: auto; }


.shaving-ul-list{ list-style: none;
	width: 400px;
	max-width: 100%;
}
.shaving-ul-list .shaving-list{
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
	padding: 10px;
	border-bottom: 1px solid #ddd;
	text-decoration: none;
	color:#000;
}
.shaving-ul-list span.shaving-list{ font-weight: bold; font-size: 30px; line-height: 1.3em; color:#3C39B7; }
.shaving-ul-list .shaving-list .title{ width: 50%; font-weight: bold; }
.shaving-ul-list .shaving-list .shaving{ width: 50%; }

.btn.blue-btn + .btn.red-btn{ margin-left: 10px; }