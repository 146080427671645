@media only screen and (max-width:1886px) {
	.hero-banner .hero-content{ max-width: 53%; position:relative;z-index:3; }
}
@media only screen and (max-width:1766px) {
	.page-banner .page-banner-content,
	.page-banner .page-banner-image,
	.hero-banner .hero-image,
	.hero-banner .hero-content{ max-width: 50%; }
}

@media only screen and (max-width:1600px) {
	.page-banner.page-banner-style-1 .page-banner-content{ min-height:1px; }
	.page-banner-image img{ max-width:100%; }
	.page-banner .page-banner-content,
	.hero-banner .hero-content{
		padding-top:50px;
		padding-bottom:50px;
	}
}
@media only screen and (max-width:1500px) {
	#main-footer .bottom-footer .wrapper{
		flex-wrap:wrap;
		flex-direction: row;
		row-gap: 40px;
		position:relative;
	}
	#main-footer .copyright{ width:100%; text-align:center; }
	#main-footer .bottom-footer .btn-flex-holder{
		position: absolute;
		top: 0;
		right: 0;
	}
	.slideshow-master .slick-slide > div,
	.custom-slide-setup{ max-width: 800px; }
	.slider-testimonial{ flex-wrap:wrap; flex-direction: row; row-gap: 40px; }
	.slider-testimonial .content{ width:100%; }
	.footer-trust-logos{ max-width: calc(100% - 250px); }
	.footer-trust-logos img{ max-width:100%; }
}

@media only screen and (max-width:1340px) {
	#nav-menu ul{ column-gap: 20px; }
}

@media only screen and (max-width:1320px) {
	h2.big, h1{
		font-size: 86px;
	}
	p.big{
		font-size: 26px;
	}
	.header-logo,
	.footer-menu-flex,
	.top-footer .main-flex{ column-gap: 40px; }
	.hero-banner{ min-height:1px; }
	
	.image-with-text-block{ column-gap: 40px; }
	.image-with-text-block .image{ width: 50%; flex: 0 0 50%; }
	.image-with-text-block img{ max-width: 100%; }

	.payment-accord-content{ padding: 30px 25px; }

	.the-checkout-content-wrap{ width: 60%; padding: 45px 10px 45px 25px; }
	.the-checkout-order-summary{ width: 40%; flex: 0 0 40%; padding: 45px 25px 45px 10px; }
	.checkout-order-summary{ padding: 30px 25px; }
	.payment-alerts-block .payment-alert{ font-size: 12px; line-height: 16px; }
	.payment-alerts-block .payment-alert .label{ margin-bottom: 5px;}
}


@media only screen and (max-width:1120px) {
	
	.the-checkout-order-summary{
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
		row-gap: 25px;
	}
	
	.landing-page-v2 .hero-banner p.big{ margin-left: auto; margin-right: auto; }
	.landing-page-v2 #header .btn-flex-holder{margin-right: auto;margin-left: auto;margin-top: 15px;}
	.landing-page-v2 #nav-icon{ display:none; }
	.landing-page-v2 .hero-banner .hero-content{ max-width:100%; }
	.landing-page-v2 .hero-banner ul li{ background: none; padding-left:0; }
	.landing-page-v2 .hero-banner ul li:before{
		content:"";
		width: 20px;
		height: 22px;
		background: url('/src/assets/images/checklist-circle.png') no-repeat top left;
		display: inline-block;
		margin-right: 10px;
		vertical-align: middle;
		top: -4px;
		position: relative;
	}
	.landing-page-v2.v2-r2 .slick-dots{ margin: 30px 0;}
	.landing-page-v2.v2-r2 .custom-slide-wrapper{ max-width: 100%; }
	.custom-slide-wrapper{
		height: auto;
		padding-top: 30px;
		padding-left: 30px;
		padding-right: 30px;
		background-size: cover;
	}
	.slideshow-master .slick-slide > div{ padding-right: 0; width: 100%; max-width: 100%; }
	.custom-slide-setup{ max-width: 100%; margin-right: 0px; }
	.slider-block .slick-list{ overflow:hidden !important; }
	
	h2.big,
	h1{
		font-size: 56px;
	}
	h1.med{ font-size: 48px; }
	h2{
		font-size: 38px;
	}
	.d-block.email-report-page .d-block-header .value{ font-size: 26px !important; }
	h3{
		font-size: 26px;
	}
	h4{
		font-size: 22px;
	}
	h5{
		font-size: 18px;
	}
	
	.intro{
		font-size: 20px;
	}
	p.big{
		font-size: 22px;
	}
	#nav-menu ul a,
	.med-text,
	p.med{
		font-size:18px;
	}
	p.small{
		font-size: 14px;
	}
	.price-table-section .price-table-title{ font-size: 30px; }
	.price-table-section .price-table-block,
	.grid-icon-boxes .grid-icon-box,
	.box-style-1{ padding: 30px; }
	
	.flexbox .wrapper{
		padding-top: 40px;
		padding-bottom:40px;
		row-gap: 40px;
	}
	
	.hero-banner .hero-image, .hero-banner .hero-content{
		max-width:100%;
		text-align: center;
	}
	.meet-the-team .first,
	.page-banner .logos,
	.hero-banner .logos,
	.hero-banner .btn-flex-holder{ justify-content: center; }
	.footer-images,
	.meet-the-team .first,
	.page-banner .logos,
	.hero-banner .logos{ flex-wrap:wrap; }
	.footer-images img,
	.meet-the-team .first img,
	.page-banner .logos img,
	.hero-banner .logos img{ max-width:100%; }
	.hero-banner .hero-image{ position: relative; }
	.landing-page-v2 .hero-banner .hero-image{margin-left:-30px;margin-right: -30px;max-width: calc( 100% + 60px);}
	.hero-banner .hero-image img{ margin: 0 auto; }
	
	.flex-wrap.content-right{ flex-wrap: wrap; }
	.flex-wrap.content-right .one-half{ width:100%; }	
	.flexbox .flex-wrap.content-right .one-half .content-block{ max-width:100%; }
	.flex-wrap.content-right .one-half:empty{ display:none; }
	.grid-icon-boxes{ display: flex; column-gap: 30px; margin-top: 30px; }
	.grid-icon-boxes .grid-icon-box{ width: 33.33%; position:relative; padding-bottom: 90px; }
	.grid-icon-boxes .grid-icon-box.one-third{		
        width: calc(33.33% - 40px);
        position: relative;
        padding-bottom: 90px;
        flex-grow: 1;
	}
	.grid-icon-boxes .grid-icon-box .learn-more{ position: absolute; bottom: 30px; left:30px; }
	.offset-content-1{ margin-top: 0; }
	
	#nav-icon{display: block;position:absolute;right: 15px;padding-right: 10px;padding-left: 12px;}
	#header .btn-flex-holder{ margin-right: 70px; }
	.checkout-page #header .btn-flex-holder{ margin-right:0; }
	
	.icon.nav-icon{display: inline-block;padding: 25px 20px;margin: 0;cursor: pointer;}
	.icon.nav-icon span, .icon.nav-icon span:before, .icon.nav-icon span:after{ 
	width: 28px; 
	height: 2px; 
	float: left; 
	display: block; 
	background: #3D3ABB; 
	position: relative; 
	text-indent: -9000px; 
	-webkit-transition: all 100ms ease-in-out;
	-moz-transition: all 100ms ease-in-out;
	-ms-transition: all 100ms ease-in-out;
	-o-transition: all 100ms ease-in-out;
	transition: all 100ms ease-in-out;
	}
	.icon.nav-icon span{ margin: 9px 0; }
	.icon.nav-icon span:before, .icon.nav-icon span:after{ content: ''; position: absolute; }
	.icon.nav-icon span:before{ top: -8px; }
	.icon.nav-icon span:after{ bottom: -8px; }
	.icon.nav-icon.active span{ background-color: transparent; }
	.icon.nav-icon.active span:before, .icon.nav-icon.active span:after{top: -2px;}
	.icon.nav-icon.active span:before{ transform: rotate(45deg); -webkit-transform: rotate(45deg); }
	.icon.nav-icon.active span:after{ transform: translateY(-10px) rotate(-45deg); -webkit-transform: translateY(-10px) rotate(-45deg); top: 8px; }
	.gradient-bg lazyload-bg{ overflow:hidden; }
	.box-style-2 .styled-content .content-area{padding-top: 30px;padding-left:30px;padding-bottom:30px;padding-right: 30px;width: 100%;height: auto;min-height: 1px;text-align: center;}
	.overflowing-image-1{width: 100%;position: relative;margin: 0 auto;}
	.overflowing-image-1 img{margin: 0 auto;max-height: 400px;}
	.box-style-2 .btn-flex-holder{ align-items:center; justify-content:center; }
	.large-padding-section{ padding-top: 50px; padding-bottom:50px;}
	
	.top-footer .main-flex{ flex-wrap: wrap; }
	.footer-contacts{width:100%;display: flex;align-items: center;justify-content: center;margin-top: 40px;column-gap: 40px;}
	.footer-menu-flex .footer-menu{ width: 25%; }
	#main-footer .top-footer{ padding-top: 40px; padding-bottom:20px;}
	.footer-menu h5{ margin-bottom:20px; }
	.footer-menu ul li{ margin-bottom:10px;}

	.price-table-section{flex-wrap: wrap;row-gap: 40px;display: flex;justify-content: center;}
	.price-table-section .price-table-block{ width: 100%; max-width: 516px; }

	.top-footer .footer-menus{ margin-right: 0; margin-left: auto; width: calc( 100% - 250px); }
	.icon-grid.col-gap-30 .one-third{width: calc(50% - 20px);}
	
	#header{position:relative;z-index: 54;}
	.main-nav{
		position: absolute;
		width: 380px;
		background: #fff;
		top: 100%;
		right: 20px;
		padding: 30px;
		-webkit-box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.06);
		-moz-box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.06);
		box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.06);
		display:none;
		max-width: calc(100% - 40px);
	}
	.main-nav.active{ display:block; }
	.main-nav ul{
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		row-gap: 20px;
	}
	.page-banner .page-wrapper{ flex-wrap: wrap; }
	.page-banner .page-banner-content, .page-banner .page-banner-image{
		width:100%; max-width:100%;
	}
	.page-banner-image{
		position:relative;
	}
	.page-banner-image img{margin: 0 auto; }
	.image-with-text-block{
		padding-top: 50px;
		padding-bottom:50px;
	}
	.blog-posts .blog-posts-block{ column-gap: 50px; }
	.blog-posts  .blog-post{ width: calc(50% - 25px) }
	
	.meet-the-team-block .one-half{
		padding: 35px;
	}
	.meet-the-team-block .one-third{ padding: 30px; }
	.meet-the-team-block img{ max-width:100%; }
	.col-gap-48{ column-gap: 30px; row-gap: 30px; }
	.the-checkout-content-wrap{ padding-left: 40px; }
	.form-block .form-field .input-text{ font-size: 18px; }
	.form-block .form-field #zipcode.input-text{ font-size: 20px; }
}
@media only screen and (max-width: 1000px) {
	.warning-section{ margin-top: 0; }
	.custom-block-style-4 .warning-icon{ margin-top: -85px; }
	.the-checkout-content{ flex-wrap:wrap; }
	.the-checkout-order-summary,
	.the-checkout-content-wrap{width:100%;padding: 40px; flex: 0 0 100%; }
	/*
	
	.the-checkout-order-summary{ padding-top:0; }
	*/
	.the-checkout-content-wrap{ padding-bottom:0; }
	
	.grid-icon-boxes .grid-icon-box.one-third{		
        width: calc(50% - 40px);
	}
	.testimonials-with-ratings .the-flex-wrap { flex-wrap: wrap; }
	.testimonials-with-ratings .the-flex-wrap .one-third{ width: 100%; }

	.custom-banner-layout-1 .mobile-only{
		display: block;
		max-width: 100%;

	}
	.custom-banner-layout-1{ background: none; }
	.custom-banner-layout-1 .wrapper{ flex-wrap: wrap; column-gap: 40px; row-gap: 40px;
        min-height: 1px; }

		.the-checkout-steps .the-checkout-step{ padding: 25px 10px; }
		.the-checkout-steps .step-title{ font-size: 18px;}

	.modal-content{ padding: 30px 20px; }
	.add-assets-actions-list li .icon-block{ margin-bottom: 5px; }
	.add-assets-actions-list li a{ text-align: center; line-height: 1.3em; padding: 10px 0; }
	.modal-close{ right: -9px; }
}
@media only screen and (max-width: 900px) {

	.form-block .form-field .input-text{ padding: 12px 14px; }
	.the-checkout-block .recurly-element, .the-checkout-block .recurly-hosted-field{
		padding: 0 14px 1px;
	}
	
	.grid-icon-boxes .grid-icon-box,
	.three-column-icon-boxes .the-boxes .the-box{
		width:100%;
	}
	.three-column-icon-boxes .the-boxes .the-box{
		margin-bottom: 32px;
	}
	.three-column-icon-boxes .the-boxes,
	.grid-icon-boxes{ flex-wrap: wrap; }

	.image-with-text-full-width.content-first .flex-wrap-setup{ flex-wrap: wrap;}
	.image-with-text-full-width.content-first .flex-wrap-setup .image,
	.image-with-text-full-width.content-first .flex-wrap-setup .content{ width: 100%; padding: 30px; }
	.image-with-text-full-width.content-first .flex-wrap-setup .content .wrap{ padding: 0;}
	.image-with-text-full-width.content-first .flex-wrap-setup .image img{ border-radius: 30px;}
	.image-with-text-full-width{ padding: 30px 0;}
	.landing-page-v2.v2-r2 .grid-icon-boxes .grid-icon-box{ width: 100%; }
	.landing-page-v2.v2-r2 .grid-icon-boxes .grid-icon-box{ padding-bottom: 30px; }
	.landing-page-v2.v2-r2 .grid-icon-boxes .grid-icon-box .learn-more{
		position: relative;
		bottom: 0;
		left: 0;
	}
}

@media only screen and (max-width:749px) {
	.back-to-previous-page{ top: -25px; }
	.payment-accord-title span{ width: 20px; height: 20px; background-size: 20px !important; }
	.payment-accord-title span{ margin-right:5px; }
	.payment-accord-title{ font-size: 14px; flex: 0 0 122px; }
	.landing-page-v2 .intro.button-type span{ padding: 8px 13px; font-size: 15px; }
	.desktop-only{ display: none; }
	.the-checkout-block .form-block.flex-wrap{ row-gap: 12px; }
	.checkout-order-summary .steps-wrap{ display: block; }
	.checkbox-1-wrapper{ display: none; }
	.mobile-checkbox-1{ margin-top: 20px; }
	.order-items-next{ margin-top: 0; }
	.the-checkout-content [data-step="3"] .desc{display: block; }
	.the-checkout-content [data-step="2"] .desc{display: none; }

	.send-message-inquiry{ padding: 30px; }
	.send-message-inquiry .send-message-contacts{ position: relative; top:0; right: 0; }
	.blog-posts  .blog-post,
	.icon-grid.col-gap-30 .one-third{width:100%;}
	.slick-slide img,
	.footer-trust-logos{ max-width:100%; }
	.number-grid .one-third,
	.footer-trust-logos,
	.footer-menu-flex .footer-menu,
	.top-footer .footer-menus,
	.grid-icon-boxes .grid-icon-box{ width:100%; }
	.secure-images .first,
	.number-grid,
	.footer-contacts,
	.footer-menu-flex,
	.grid-icon-boxes{ flex-wrap: wrap; }
	.top-footer .main-flex,
	.footer-menu-flex{ row-gap: 40px; }
	.footer-contacts{
		flex-direction:column;
		margin-top: 0;
	}
	#main-footer .bottom-footer .btn-flex-holder{position: relative;justify-content: center;align-items: center;width: 100%;}
	#header{
		padding-top: 5px;
		padding-bottom:5px;
	}
	#header .btn-flex-holder{margin-right: 40px;}
	.btn{font-size: 15px;padding-left: 20px;padding-right: 20px;}
	.header-logo{max-width:40%;margin-left: -15px;}
	.landing-page-v2 #header .btn{ flex: auto; }
	.landing-page-v2 .custom-page-content-block-1 .flex-wrap .one-half{ width:100%;}
	.landing-page-v2 .custom-page-content-block-1 .flex-wrap,
	.landing-page-v2 .logos,
	.landing-page-v2 #header > .wrapper{ flex-wrap: wrap; }
	.landing-page-v2 #header > .wrapper{
	    display: flex;
	    justify-content: center;
	    text-align: center;
	}
	.landing-page-v2 .custom-page-content-block-1 .page-content-block{ padding: 20px;}
	.landing-page-v2 .logos{ column-gap:10px;}
	.landing-page-v2 .logos img{max-height: 30px;}
	.landing-page-v2 .header-logo{max-width:100%;flex-wrap: wrap;align-items: center;display: flex;justify-content: center;row-gap: 15px;width: 100%; margin-left: 0;}
	.logo img{max-width:100%;}
	.custom-block-style-1{ background-size:cover; }
	.landing-page-v2 .hero-banner .btn-flex-holder{ margin-bottom:20px; }
	.landing-page-v2 .hero-banner .btn-flex-holder .have-hack-long-btn{ margin-left: -20px; margin-right: -20px;gap: 0; }
	.landing-page-v2 .hero-banner .btn-flex-holder .have-hack-long-btn svg{ margin-left: 3px;  }

	.landing-page-v2 .site-slogan{ width:100%; }
	
	h2.big,
	h1{
		font-size: 46px;
	}
	h1.med{ font-size: 40px; }
	h2{
		font-size: 32px;
	}
	.d-block.email-report-page .d-block-header .value{ font-size: 24px !important; }
	h3{
		font-size: 24px;
	}
	
	.the-checkout-steps .step-title,
	h4{
		font-size: 20px;
	}
	h5{
		font-size: 16px;
	}
	
	.intro{
		font-size: 18px;
	}
	p.big{
		font-size: 20px;
	}
	.price-table-list ul li,
	#nav-menu ul a,
	.med-text,
	p.med{
		font-size:16px;
	}
	p.small{
		font-size: 14px;
	}
	.price-table-section .price-table-title{ font-size: 24px; }
	.btn-flex-holder{ column-gap: 5px; justify-content: center; }
	.overflowing-image-1 img{ max-width:100%;}
	
	.image-with-text-block{
		flex-wrap: wrap;
		flex-direction: column;
		
	}
	.image-with-text-block .image{
		width:100%;
		flex: 0 0 100%;
		margin-bottom: 40px;
	}
	.three-column-icon-boxes .the-boxes, .three-column-icon-boxes .small-page-content-block{
		margin-bottom: 40px;
	}
	.med-padding-section{
		padding-top: 40px;
		padding-bottom:40px;
	}
	.faded-sliders:before,
	.faded-sliders:after{
		width: 20px;
	}
	.the-slider-icons .the-box{ max-width:100%; }
	.the-slider-icons .slick-list{ overflow:hidden !important; }

	.landing-page-v2 .grid-icon-boxes .grid-icon-box{ padding-bottom: 40px; }
	
	.meet-the-team-block .one-half{
		width:100%;
	}
	.meet-the-team-block .one-third{ width: calc(50% - 40px); }
	
	.flex-wrap.mobile-one-block{ flex-wrap:wrap; }
	.flex-wrap.mobile-one-block > div{ width:100%; }
	#page-container.login-page .logo-form-wrapper{ padding: 25px; }

	
	.form-block.flex-wrap .form-field.one-half{ flex: auto; width:100%; }

	.the-checkout-steps{ flex-wrap:wrap; }
	.the-checkout-steps .the-checkout-step{width:100%;display: flex;align-items: center;column-gap: 10px;justify-content: flex-start; }
	.checkout-section.thank-you{ padding-top: 0; margin-top: 0; }
	.get-help.btn.blue-btn{ padding: 14px 15px; }
	.checkout-section{ padding: 30px;}
	.the-checkout-steps .the-checkout-step{ padding: 10px 20px;}
	.the-checkout-order-summary, .the-checkout-content-wrap{
		padding-left:20px;
		padding-right: 20px;
	}
	.the-checkout-order-summary{
		margin: 0 auto;
		flex: 0 0 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
	}
	.the-checkout-order-summary .flex-wrap.flex-wrap-block{flex-wrap: nowrap; }
	.payment-alerts-block .payment-alert{
        min-width: 33%;
        padding: 15px 2px;
        font-size: 11px;
	}
	.payment-accord-content{ padding: 15px; }
	.thank-you-customer-info .flex-wrap{ flex-wrap: wrap; }
	.thank-you-customer-info .flex-wrap .one-half{ width:100% }
	.price-table-section .price-table-block.featured:before{
		right: 20px;
		width: 35px;
		height: 34px;
	}
	.landing-page-v2 .pricing-single-block{ padding: 20px;}
	.custom-block-style-2.bg-1{ background-size:cover; }
	
	.steps-block.hidden-blocks{
		display: block;
	}
	.steps-block.hidden-blocks .steps-wrap{ display: none; }
	.the-checkout-content-wrap{ padding: 0; }
	.steps-block.active .steps-wrap{ padding: 20px; }

	.mobile-save-and-continue{ display: none; margin-bottom: 0; }
	.the-checkout-block p:last-of-type{ margin-bottom: 0; }
	.mobile-title{
		border-top: 1px solid #ACD6FF;
		transition: all 0.3s ease;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		column-gap: 10px;
		display: flex;
		align-items: center;
		column-gap: 10px;
		justify-content: flex-start;
		padding: 10px 20px;
	}
	.steps-block[data-step="3"].active .mobile-title,
	.steps-block.active .mobile-title{ border-bottom: rgba(61,58,187, 1) 1px solid;border-top: rgba(61,58,187, 1) 1px solid; }
	.steps-block[data-step="3"] .mobile-title{ border-bottom: 1px solid #ACD6FF; }
	.steps-block[data-step="1"] .mobile-title{ border-top: none !important; }
	.mobile-title .label{
		color: rgba(61,58,187, 0.3);
		font-weight: bold;
	}
	.mobile-title .num{
		display: inline-flex;
		width: 31px;
		height: 31px;
		border-radius: 100%;
		border: 2px solid rgba(61,58,187, 0.3);
		text-align: center;
		align-items: center;
		justify-content: center;
		margin-right: 5px;
		font-weight: bold;
		color: rgba(61,58,187, 0.3);
		flex: 0 0 31px;
	}
	.active .mobile-title .label {
		color: rgba(61,58,187, 1);
	}
	.active .mobile-title .num{
		background-color: rgba(61,58,187, 1);
		border-color: rgba(61,58,187, 1);
		color: #fff;
	}
	.the-checkout-steps{ display: none; }
	
	.grid-icon-boxes .grid-icon-box.one-third{		
        width: 100%;
	}
	.ip-block{ background-size: cover;}

	#main-footer .copyright{
		display: flex;
		flex-direction: column;
		row-gap: 10px;
		align-items: center;
	}
} 

@media only screen and (max-width:480px) {
	h2.big,
	h1{
		font-size: 40px;
	}
	h1.med{ font-size: 34px; }
	h2{
		font-size: 28px;
	}
	h3{
		font-size: 20px;
	}
	.the-checkout-steps .step-title,
	h4{
		font-size: 18px;
	}
	h5{
		font-size: 16px;
	}
	
	.intro{
		font-size: 16px;
	}
	p.big{
		font-size: 18px;
	}
	.price-table-list ul li,
	#nav-menu ul a,
	.med-text,
	p.med{
		font-size:16px;
	}
	p.small{
		font-size: 14px;
	}
	.btn {
		padding-left: 15px;
		padding-right: 15px;
	}
	.price-table-section .price-table-price .price{ font-size: 50px; }
	.meet-the-team-block .one-third{ width: 100%; text-align:left; }
	.team-member-block .image img{ margin: 0; }

	.checkout-page #header .wrapper{
		display: flex;
		flex-direction: column;
		padding-top: 20px;
		row-gap: 10px;
	}
	.checkout-section{
		margin-top: 20px;
		padding: 0;
	}
	.payment-alerts-block,
	.checkout-order-summary h3,
	.the-checkout-content-wrap h3{ margin-bottom: 15px; }

	.checkout-order-summary{ padding: 20px; }
	.order-summary-item{ position: relative; padding-left: 80px; flex-direction: column; }
	.order-summary-item .order-summary-image{
		position: absolute;
		top:0;
		left: 0;
	}
	[data-step="1"] .form-block:nth-child(4){ margin-bottom: 0; }
	.order-summary-item .order-summary-details{ width: 100%; }	
	.order-summary-price {
		text-align: left;
		padding-top: 5px;
        width: 100%;
        display: flex;
        gap: 10px;

	}
	.payment-alerts-block .alert-text{
		display: flex;
		width: 100px;
		margin: 0 auto 10px;
	}
	.the-checkout-block p:last-of-type{ margin-bottom: 0;}
	.form-block .form-field .form-field-label{ margin-bottom: 3px; }
	.form-block.flex-wrap{ row-gap: 12px; }


}


@media only screen and (max-width:400px) {
	.payment-accord-header-image img{ width: 24px; }
	h2.big,
	h1{
		font-size: 36px;
	}
	h1.med{ font-size: 30px; }
	h2{
		font-size: 24px;
	}
	h3{
		font-size: 18px;
	}
	.the-checkout-steps .step-title,
	h4{
		font-size: 16px;
	}
	h5{
		font-size: 14px;
	}
	
	.intro{
		font-size: 14px;
	}
	p.big{
		font-size: 16px;
	}
	.price-table-list ul li,
	#nav-menu ul a,
	.med-text,
	p.med{
		font-size:16px;
	}
	p.small{
		font-size: 14px;
	}
	#header .btn.blue-btn svg{ display: none; }
	.order-summary-item{ padding-left: 60px; }
	.order-summary-item .order-summary-image img{ width: 48px; }
	.order-summary-details .order-title{ font-size: 14px; }
	.landing-page-v2.v2-r2 .custom-slide-wrapper{ padding: 30px 25px 100px; }
	.add-asset-container .form-field.checkbox-field, .the-checkout-block .form-field.checkbox-field{
		padding-left: 30px;
	}
	.add-asset-container .form-field.checkbox-field label span, .the-checkout-block .form-field.checkbox-field label span{ width: 20px; height: 20px; }
	.add-asset-container .form-field.checkbox-field input:checked + label span, .the-checkout-block .form-field.checkbox-field input:checked + label span {
		background-size: 14px;
	}
}

@media only screen and (max-width:300px) {
	.landing-page-v2 .hero-banner .btn-flex-holder .have-hack-long-btn{ font-size: 12px; }
	.landing-page-v2 .hero-banner .btn-flex-holder .have-hack-long-btn svg{ display: none; }
}